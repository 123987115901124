// 定义内容
export default {
	apply: {
    Progress: "申请进度",
    comment: "备注",
    applyDate: "申请时间",
    applicationSuccess: "申请成功",
    applying: "申请中",
    applicationFailed: "申请失败",
    productName: '产品名称',
    registrationStatus: "状态",
    registrationDate: "时间",
  }
};