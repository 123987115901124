export default {
  product: {
    annualization: '年化です',
    retract: '引き揚げです',
    riskType: 'リスクタイプです',
    radicalType: '急進型です',
    balancedType: 'バランス型です',
    conservativeType: '保守型です',
    timeLimit: '期限です',
    managementFee: '管理費です',
    productAmount: '製品金額です',
    buy: '購入します',
    day: '天です',
    details: {
      details: '詳細です',
      product: '製品名です',
      lever: 'てこ',
      group: '組です',
      productDescription: '製品説明です',
    }
  }
};