// 日本语
export default {
	netWorkError: 'ネットワークエラー',
	wrongNameOrPassword: 'ユーザー名またはパスワードが間違っています',
	forbiddenToLogin: 'ログイン禁止',
	success: '成功',
	emailFormatError: 'メールボックスのフォーマットが正しくありません',
	pwdLengthError: 'パスワード長は6～18ビット',
	pwdStrongError: 'パスワードには数字と大文字と小文字が必要です',
	mailAlreadyExists: 'アカウントは登録済みですので、メールアドレスを交換してください',
	registerSuccess: 'メール送信に成功しました。メールボックスで確認してください!',
	registerFailed: '登録に失敗しました。カスタマーサービスに連絡してください',
	activeExpired: 'アカウントの検証に失敗しました。もう一度試してください',
	activeSuccess: 'アカウントの検証に成功しました',
	sendMailSuccess: 'メール送信成功',
	accountDisabled: 'アカウントが無効になっています',
	sendMailFailure: 'メール送信に失敗しました。カスタマーサービスに連絡してください',
	emailNotExist: 'メールアドレスが存在しません',
	setPasswordSuccess: 'パスワードのリセットに成功しました',
	setPasswordFailureTryAgain: 'パスワードのリセットに失敗しました。再試行してください',
	oldPwdNotMatch: '古いパスワードが一致しません',
	failedTryAgain: '操作に失敗しました。再試行してください',
	errorInput: '入力された情報が間違っているので、チェックしてください',
	signalHasPositions: '号源には持倉がある',
	hasSubscription: '信号源には荷為替口座があり、操作を完了できません!',
	alreadySubmitUnScribe: '退避申請を提出しました',
	closePositionsFirst: 'すべての在庫注文をクローズしてください',
	loginNotExist: 'アカウントは存在しません',
	alreadyWasSignal: 'すでに信号源',
	alreadyCopyTrade: '荷為替中',
	alreadyFollowTheSignal: '現在の信号と',
	thisLoginSubmitSignalApply: 'シグナル伝達済み',
	alreadySubmitCopyApply: '荷為替依頼を提出しました',
	noBankCard: '入金銀行を追加してください',
	verifyExpired: "検証に失敗しました",
	notEnoughMoney: "使用可能残高が不足しています",
	subscribersOnlyWithdrawOnSunday: "荷為替ユーザーは毎週日曜日に出金申請を提出してください!",
	maxMinDeposit: "最小チャージ金額:",
	minWithdrawal: "最低引出金額:",
	dailyWithdrawalsLimit: "現金引き出しが1日の上限額に達した!",
	doNotRepeatUpload: "繰り返しアップロードしないでください",
	alreadyMaxCount: 'アカウント数が上限に達しました',
	loginTypeError: "間違ったアカウントカテゴリ",
	loginOutOfRange: "アカウントが範囲外です!",
	codeSendSuccess: "認証コードの送信に成功しました!",
	gaVerifyFailed:"google認証システムの認証に失敗しました",
	keepOneVerifyAtLeast:"少なくとも1つのセキュリティ検証を保持します",
	notEnoughScore:"ポイントが足りません。",
	clockInAlready:"はリオネル・メッシー",
	demoOpenSuccess:"模擬口座開設に成功しました,メールチェックにご注意ください"
};
