import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import Particles from 'vue-particles';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/theme/index.scss';
import { i18n } from '@/i18n/index.js';
import { globalComponentSize } from '@/utils/componentSize.js';

// 图标 font-awesome
import 'font-awesome/css/font-awesome.min.css';

/** iconfont
 * Symbol 引用
 */
 import '@/assets/iconfont/iconfont.css'

// 转盘抽奖
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)



Vue.use(Particles);
Vue.use(Element, { i18n: (key, value) => i18n.t(key, value), size: globalComponentSize });

Vue.config.productionTip = false;
Vue.prototype.bus = new Vue();

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
