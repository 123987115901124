// 定义内容
export default {
  signal: {
    tips: "نکات",
    agree: "تایید",
    refuse: "انصراف",
    OK: "تایید",
    cancel: "لغو",
    Submit: "ثبت",
    signalName: "نام سیگنال",
    equity: "موجودی جاری",
    balance: "بالانس",
    operation: "عملیات",
    direction: "الگوبرداری مسیر",
    positive: "مثبت",
    reverse: "وارونه",
    tradeType: "الگوبرداری نوع سفارش",
    percentage: "درصد",
    fixedLots: "ات های معین",
    equityPercentage: "LanguageModel",
    list: {
      all: "همه",
      proSignal: "سیگنال های عمومی",
      socialSignal: "سیگنال های دامنه خصوصی",
      monthlyProfit: "ماهانه(%)",
      currentProfit: "سود(%)",
      dailyProfit: "سودآوری روزانه(%)",
      maxDrawdown: "بیشترین حد کاهش سرمایه(%)",
      leverage: "اهرم",
      profit: "سود",
      lots: "لات ها",
      weeklyProfit: "سود یک هفته",
      applyForCopy: "برای الگوبرداری معاملات درخواست دهید",
      tips_text: 'شما در حال کپی کردن سیگنال هستید، اگر "موافق" را انتخاب کنید، به این معنی است که شما اطلاعات زیر را درک کرده اید و موافقت کرده اید که اطلاعات زیر را بپذیرید.',
      tips_text1: "من اگاه بوده ام که تجارت با حساب سیگنال، تنظیمات پارامتر مربوطه رفتار شخصی و مستقل است، کاملا از خطرات تجاری این عملیات اگاه است و داوطلبانه تمام نتایج سود و زیان حساب ناشی از تجارت حساب سیگنال را صرف نظر از دیگران تحمل می کند.",
      tips_text2: "من از قوانین و هزینه های کپی کردن معاملات با حساب سیگنال اگاه هستم و موافقت می کنم که هزینه های مربوط به اشتراک سیگنال ناشی از عملیات کپی مربوطه را از حساب کپی خود کسر کنم.",
      tips_text3: "من اگاه هستم که حساب های سیگنال در منبع سیگنال جامعه کپی توسط اکثر علاقه مندان به تجارت ارائه می شود و XXXX مسئول نتایج معاملاتی و خطرات هر حساب سیگنال نیست.",
      applyTrade: "اشتراک",
      applyTradeTips: "نکته: کپی حساب ها را نمی توان تکرار کرد و کپی حساب ها نمی توانند سیگنال باشند",
      tradeLogin: "مشترکین",      
      tradeValue: "ارزش معامله-الگوبرداری شده",
      percentageLeast1: "حداقل درصد لات های کپی 1 است",
      fixedLotsLeast1: "اندازه لات ثابت حداقل 0.01 است"      
    },
    myfollow: {
      tradingAccount: "حساب معامله گری",
      signalState: "وضعیت سیگنال",
      Normal: "دستی",
      Unavailable: "غیر قابل دسترس",
      creationTime: "تاریخ تولید",
      unSubscribe: "لغو اشتراک",
      applyForUnsubscribe: "درخواست لغو اشتراک",
    },
    userSignal: {
      createSignal: "ساخت سیگنال",
      tips_text1: "موجودی حساب سیگنال کمتر از 100 دلار است و هیچ افزایش وجوه، هیچ معامله و هیچ کپی در عرض یک هفته از سیستم جامعه کپی حذف نخواهد شد.",
      tips_text2: "حساب های سیگنال مشکوک به استفاده از روش های تجارت غیرقانونی برای اربیتراژ مخرب برای 30 روز طبیعی مسدود خواهند شد و ورود به سیستم MT4 و CRM نمی تواند در طول دوره انجماد حساب سیگنال انجام شود. در طول دوره انجماد حساب سیگنال، سود یا زیان سفارشات باز در حساب باید توسط دارنده حساب سیگنال پرداخت شود.",
      tips_text3: "حساب های سیگنالی که تایید شده اند از روش های معاملاتی غیرقانونی برای اربیتراژ مخرب استفاده می کنند، به طور دائم از حساب سیگنال و حساب با همین نام ممنوع خواهند شد و دارنده حساب سیگنال در لیست سیاه سیگنال قرار می گیرد و از باز کردن حساب در XXXXX ممنوع است. سود و زیان از سفارشات باز در طول دوره تعلیق از حساب سیگنال باید توسط دارنده حساب سیگنال به عهده.",
      MT4Account: "حساب متا تریدر 4",
      profitShareAccount: "حساب اشتراک سود",
      signalCommissions: "کمیسیون سیگنال",
      referralCommission: "کمیسیون معرف",
      tradeMethod: "وسیله معامله",
      manual: "کتابچه راهنمای",
      other: "دیگر",
      percentage: "درصد",
      applyDate: "تاریخ",
      comment: "نظر",
      dataUpdateTime: "زمان به‌روزرسانی داده‌ها",
      disabledSignal: "غیر فعال کردن سیگنال",
      delete: "حذف",
      activateSignal: "فعال سازی سیگنال",
      applying: "در حال اجرای درخواست",
      noIdleAccount: "بدون اکانت بی استفاده"
    },
    details: {
      balanceDetails: "جزئیات",
      fundBalance: "بالانس",
      equityDetails: "نمودار ارزش خالص",
      deposit: "واریز",
      netProfit: "سود خالص",
      profitRatio: "نرخ سود",
      profitOrders: "سود",
      lossOrders: "ضرر",
      trades: "معاملات",
      signalDetail: "جزئیات سیگنال",
      totalDeposit: "مجموع واریزی ها",
      totalWithdrawal: "مجموع برداشتی ها",
      profit: "سود",
      loss: "ضرر",
      profitRatioNumber: "ضریب سود",
      volume: "حجم",
      orderQuantity: "مقدار سفارش",
      longPositionOrders: "سفارشات معاملاتی خرید",
      shortPositionOrders: "سفارشات معاملاتی فروش",

      signalIntroduction: "مقدمه ای بر سیگنال ها",
      dataStatistics: "آمارهای داده",
      profitFactor: "عامل سود",
      symbolsStatistics: "نماد",

      orderDetails: "جزئیات سفارش",
      type: "نوع",
      lots: "لات ها",
      symbols: "نماد",
      openTime: "زمان باز شدن",
      openPrice: "قیمت باز شده",
      stopLoss: "حد ضرر",
      takeProfit: "حد سود",
      closeTime: "زمان بسته شدن",
      closePrice: "قیمت بسته شده",
      commission: "کمیسیون",
      swap: "بهره",
      tableProfit: "سود",
      tradeProfile: "مشخصات تراکنش",
      volume: "حجم",
      tradingDays: "روزهای معامله گری",
      day: "روز",
      maxDrawdown: "حداکثر کاهش",
      weeklyProfit: "سود یک هفته",
      monthlyProfit: "سود ماه گذشته",
      totalProfit: "مجموع سود"
    }
  }
};
