// 定义内容
export default {
  signal: {
    tips: "提示",
    agree: "同意",
    refuse: "拒絕",
    OK: "確定",
    cancel: "取消",
    Submit: "提交申請",
    signalName: "訊號名稱",
    equity: "淨值",
    balance: "餘額",
    operation: "操作",
    direction: "跟單方向",
    positive: "正常跟單",
    reverse: "反向跟單",
    tradeType: "跟單類型",
    percentage: "跟單手數百分比",
    fixedLots: "固定手數",
    equityPercentage: "淨值比例",
    list: {
      all: "全部",
      proSignal: "公共信號",
      socialSignal: "私域信號",
      monthlyProfit: "月化(%)",
      currentProfit: "當前盈利(%)",
      dailyProfit: "日盈利率(%)",
      maxDrawdown: "最大回撤(%)",
      leverage: "槓桿",
      profit: "獲利",
      lots: "手數",
      weeklyProfit: "近一周收益",
      applyForCopy: "申請跟隨",
      tips_text: "您正在進行信號跟單操作，如您選擇“同意”則表示您已知悉並同意接受以下信息內容",
      tips_text1: "本人已知悉跟隨信號賬戶進行交易, 其相關參數設置屬於個人自主行爲, 完全知悉此操作存在的交易風險，並自願承擔跟隨信號賬戶交易所導致的賬戶盈虧的一切結果, 與他人無關。",
      tips_text2: "本人已知悉跟隨信號賬戶進行跟單交易的規則和收費標準，同意從本人跟單賬戶中扣除相關跟單操作所產生的信號訂閱相關費用。",
      tips_text3: "本人已知悉跟單社區信號源中的信號賬戶是由廣大交易愛好者提供, XXXX不對任何信號賬戶的交易結果和風險負責。",
      applyTrade: "申請跟單",
      applyTradeTips: "提示：跟單帳號不能重複跟單，且跟單帳號不能是信號源",
      tradeLogin: "跟單帳號",      
      tradeValue: "跟單類型值",
      percentageLeast1: "跟單手數百分比最少爲1",
      fixedLotsLeast1: "固定手數最少爲0.01"      
    },
    myfollow: {
      tradingAccount: "交易賬號",
      signalState: "信號源狀態",
      Normal: "正常",
      Unavailable: "不可用",
      creationTime: "創建時間",
      unSubscribe: "撤銷跟單",
      applyForUnsubscribe: "已提交撤離跟單申請",
    },
    userSignal: {
      createSignal: "申請成為信號源",
      tips_text1: "信號賬戶餘額低於100$，且在一週之內無增加資金、無交易、無跟單的信號賬號會被跟單社區系統下架",
      tips_text2: "涉嫌使用違規交易手段進行惡意套利的信號賬戶， 將會被凍結30個自然日，信號賬戶凍結期間，不能進行MT4及CRM系統登錄；信號賬戶凍結期間，賬戶中的持倉訂單盈虧由信號賬戶持有人自行承擔。",
      tips_text3: "確認使用違規交易手段進行惡意套利的信號賬戶，其信號賬戶和同名賬戶將會被永久封號，信號賬戶持有人將被列入信號黑名單及禁止在XXXXX進行開戶交易。其信號賬戶封號期間的持倉訂單盈虧由信號賬戶持有人自行承擔。",
      MT4Account: "MT4賬戶",
      profitShareAccount: "分紅賬號",
      signalCommissions: "信號佣金",
      referralCommission: "推薦人佣金",
      tradeMethod: "交易方式",
      manual: "手動",
      other: "其他",
      percentage: "百分比",
      applyDate: "申請時間",
      comment: "備註",
      dataUpdateTime: "數據更新時間",
      disabledSignal: "設定為禁用",
      delete: "删除",
      activateSignal: "申請啟用",
      applying: "申請中",
      noIdleAccount: "沒有空閒的帳號"
    },
    details: {
      balanceDetails: "資金走勢圖",
      fundBalance: "資金",
      equityDetails: "淨值走勢圖",
      deposit: "入金",
      netProfit: "淨利潤",
      profitRatio: "盈利率",
      profitOrders: "盈利訂單",
      lossOrders: "虧損訂單",
      trades: "訂單個數",
      signalDetail: "訊號詳情",
      totalDeposit: "總入金",
      totalWithdrawal: "總出金",
      profit: "盈利",
      loss: "虧損",
      profitRatioNumber: "盈利係數",
      volume: "交易量",
      orderQuantity: "訂單數量",
      longPositionOrders: "多單數量",
      shortPositionOrders: "空單數量",

      signalIntroduction: "信號簡介",
      dataStatistics: "資料統計",
      profitFactor: "勝率分析",
      symbolsStatistics: "交易品種統計",

      orderDetails: "訂單詳情",
      type: "類型",
      lots: "手數",
      symbols: "交易品種",
      openTime: "開倉時間",
      openPrice: "開倉價格",
      stopLoss: "止損",
      takeProfit: "止盈",
      closeTime: "平倉時間",
      closePrice: "平倉價格",
      commission: "手續費",
      swap: "庫存費",
      tableProfit: "獲利",
      tradeProfile: "交易概況",
      volume: "總交易量",
      tradingDays: "訊號天數",
      day: "天",
      maxDrawdown: "最大回撤",
      weeklyProfit: "近一周收益",
      monthlyProfit: "近一月收益",
      totalProfit: "總收益"
    }
  }
};
