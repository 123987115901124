<template>
	<div id="app">
		<router-view />
		<!-- 布局配置 -->
		<Setings ref="setingsRef" />
		<!-- 更新 -->
		<!-- <Upgrade v-if="getVersion" /> -->
	</div>
</template>

<script>
// var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
// (function () {
// 	var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
// 	s1.async = true;
// 	s1.src = 'https://embed.tawk.to/614bd4ea25797d7a89005c46/1fg83fddk';
// 	s1.charset = 'UTF-8';
// 	s1.setAttribute('crossorigin', '*');
// 	s0.parentNode.insertBefore(s1, s0);
// })();
import config from '/package.json';
import { Local, Session } from '@/utils/storage.js';
import Setings from '@/layout/navBars/breadcrumb/setings.vue';
// import Upgrade from '@/layout/upgrade/index.vue';
export default {
	name: 'App',
	components: { Setings },
	mounted() {
			const targetPath = Local.get('targetPath');
			if (targetPath) {
				Local.remove('targetPath');
				this.$router.push(targetPath);
			}
		this.openSetingsDrawer();
		this.getLayoutThemeConfig();
	},
	computed: {
		// 获取版本号
		getVersion() {
			let isVersion = false;
			if (this.$route.path !== '/login') {
				if ((Local.get('version') && Local.get('version') !== config.version) || !Local.get('version')) isVersion = true;
			}
			return isVersion;
		},
	},
	methods: {
		// 设置初始化，防止刷新时恢复默认
		// 布局配置弹窗打开
		openSetingsDrawer() {
			this.bus.$on('openSetingsDrawer', () => {
				this.$refs.setingsRef.openDrawer();
			});
		},
		// 获取缓存中的布局配置
		getLayoutThemeConfig() {
			if (Local.get('themeConfigPrev')) {
				this.$store.dispatch('themeConfig/setThemeConfig', Local.get('themeConfigPrev'));
				document.documentElement.style.cssText = Local.get('themeConfigStyle');
			} else {
				Local.set('themeConfigPrev', this.$store.state.themeConfig.themeConfig);
			}
		},
	},
	watch: {
		// 监听路由的变化
		$route: {
			handler(to) {
				this.$nextTick(() => {
					if ((to.path === '/login' || to.path === '/register' || to.path === '/forgetpassword' || to.path === '/setnewpwd' || to.path === '/401' || to.path === '/404')) {
						document.title = this.$t(to.meta.title)
					}else {
						document.title = this.$t('message.app.clientManagementSystem')
					}
					if(Session.get('userInfo') ? Session.get('userInfo').active != 3 : false){
						this.$router.push('/init');
					}
				});
			},
			deep: true,
			immediate: true,
		},
    '$i18n.locale'(newValue, oldValue) {
			let to = this.$route;
			this.$nextTick(() => {
				if ((to.path === '/login' || to.path === '/register' || to.path === '/forgetpassword' || to.path === '/401' || to.path === '/404')) {
					document.title = this.$t(to.meta.title)
				}else {
					document.title = this.$t('message.app.clientManagementSystem')
				}
			});
    }
	},
	destroyed() {
		this.bus.$off('openSetingsDrawer');
	},
};
</script>
<style>
.el-button:hover{cursor: pointer;}
</style>
