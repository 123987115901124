// 定义内容
export default {
	report: {
    startTime: "زمان شروع",
    endTime: "زمان پایان",
    order: "سفارش",
    account: "حساب",
    type: "نوع",
    lots: "لات ها",
    symbols: "نماد",
    stopLoss: "حد ضرر",
    takeProfit: "حد سود",
    openTime: "زمان باز شدن",
    openPrice: "قیمت باز شده",
    closePrice: "قیمت بسته شده",
    closeTime: "زمان بسته شدن",
    commissionPrice: "کمیسیون",
    profit: "سود",
    total: "مجموع",
    closing: {
      swap: "بهره",
    },
    position: {
      time: "زمان",
      price: "قیمت",
      currentPrice: "قیمت",
      swap: "بهره",
    },
    performance: {
      name: "نام",
      date: "زمان",
      comment: "نظر",
      amount: "بالانس",
    },
    commission: {
      account_order: "سفارش",
      MT4account: "حساب متاتریدر 4",
      type: "نوع",
      volume: "حجم",
      Commission: "کمیسیون"
    },
    deviden: {
      comment: "نظر",
      time: "زمان",
      profit: "سود"
    }
  }
};
