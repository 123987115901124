// 定义内容
export default {
	login: {
		forgetpassword: 'Forgot password?',
		noaccount: 'No account?',
    registernow: 'Open a New Account',
		pleaseinputaccount: 'Please enter your account',
		pleaseinputpassword: 'Please enter your password',
		placeholder3: 'Please enter the verification code',
		btnText: 'Login',
		// link: {
		// 	one1: '第三方登录',
		// 	one2: '友情链接',
		// },
		signInText: 'Welcome back!',
	},
	Register: {
		register: 'Register',
		hasAccount: 'Already have an account',
    loginNow: 'Login',
    userEmail: 'Email',
    LastName: 'Last Nam',
    FirstName: 'First Name',
		userPhone: 'Phone',
    userPassword: 'Password',
    userPasswordAgain: 'Confirm password',
    userAgent: 'Referral',
    pwdNotMatch: 'Two passwords do not match.',
    newDemoAccount: "New Demo Account",
		Leverage: 'Leverage',
		Cellhone: 'Cellhone',
		form: 'Customer source',
		verificationCode: 'Verification code',
		pleaseSelectLever: 'Please select lever',
		pleaseEnterCustomerSource: 'Please enter customer source'
	},
	ForGetPassword: {
		forgetpassword: "Forgot password",
		retrievePassword: "Retrieve Password",
		pleaseRegisterEmail: "Please entre the email which used for registration.",
		Confirm: "Confirm",
		SetNewPwd: "Reset Password"
	}
};
