import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zhcnLocale from 'element-ui/lib/locale/lang/zh-CN';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhtwLocale from 'element-ui/lib/locale/lang/zh-TW';
import faLocale from 'element-ui/lib/locale/lang/fa';
import jpLocale from 'element-ui/lib/locale/lang/ja';
import thLocale from 'element-ui/lib/locale/lang/th';
import arLocale from 'element-ui/lib/locale/lang/ar';
//import hiLocale from '@/i18n/pages/ele/hi';
//import msLocale from '@/i18n/pages/ele/ms';
import store from '@/store/index.js';
import { Local } from '@/utils/storage';

import nextZhcn from '@/i18n/lang/zh-cn.js';
import nextEn from '@/i18n/lang/en.js';
import nextZhtw from '@/i18n/lang/zh-tw.js';
import nextFa from '@/i18n/lang/fa.js';  
import nextJp from '@/i18n/lang/jp.js';
import nextTh from '@/i18n/lang/th.js';
import nextAr from '@/i18n/lang/ar.js';
import nextHi from '@/i18n/lang/hi.js';
import nextMs from '@/i18n/lang/ms.js';

import pagesHomeZhcn from '@/i18n/pages/home/zh-cn.js';
import pagesHomeEn from '@/i18n/pages/home/en.js';
import pagesHomeZhtw from '@/i18n/pages/home/zh-tw.js';
import pagesHomeFa from '@/i18n/pages/home/fa.js';
import pagesHomeJp from '@/i18n/pages/home/jp.js';
import pagesHomeTh from '@/i18n/pages/home/th.js';
import pagesHomeAr from '@/i18n/pages/home/ar.js';
import pagesHomeHi from '@/i18n/pages/home/hi.js';
import pagesHomeMs from '@/i18n/pages/home/ms.js';

import pagesLoginZhcn from '@/i18n/pages/login/zh-cn.js';
import pagesLoginEn from '@/i18n/pages/login/en.js';
import pagesLoginZhtw from '@/i18n/pages/login/zh-tw.js';
import pagesLoginFa from '@/i18n/pages/login/fa.js';
import pagesLoginJp from '@/i18n/pages/login/jp.js';
import pagesLoginTh from "@/i18n/pages/login/th.js";
import pagesLoginAr from "@/i18n/pages/login/ar.js";
import pagesLoginHi from "@/i18n/pages/login/hi.js";
import pagesLoginMs from "@/i18n/pages/login/ms.js";

import pagesCustomerZhcn from '@/i18n/pages/customer/zh-cn.js';
import pagesCustomerEn from '@/i18n/pages/customer/en.js';
import pagesCustomerZhtw from '@/i18n/pages/customer/zh-tw.js';
import pagesCustomerFa from '@/i18n/pages/customer/fa.js';
import pagesCustomerJp from '@/i18n/pages/customer/jp.js';
import pagesCustomerTh from '@/i18n/pages/customer/th.js';
import pagesCustomerAr from '@/i18n/pages/customer/ar.js';
import pagesCustomerHi from '@/i18n/pages/customer/hi.js';
import pagesCustomerMs from '@/i18n/pages/customer/ms.js';

import adminmanagerZhcn from '@/i18n/pages/customerAdminmanager/zh-cn.js';
import adminmanagerEn from '@/i18n/pages/customerAdminmanager/en.js';
import adminmanagerZhtw from '@/i18n/pages/customerAdminmanager/zh-tw.js';
import adminmanagerFa from '@/i18n/pages/customerAdminmanager/fa.js';
import adminmanagerJp from '@/i18n/pages/customerAdminmanager/jp.js';
import adminmanagerTh from '@/i18n/pages/customerAdminmanager/th.js';
import adminmanagerAr from '@/i18n/pages/customerAdminmanager/ar.js';
import adminmanagerHi from '@/i18n/pages/customerAdminmanager/hi.js';
import adminmanagerMs from '@/i18n/pages/customerAdminmanager/ms.js';

import pagesSignalZhcn from '@/i18n/pages/signal/zh-cn.js';
import pagesSignalEn from '@/i18n/pages/signal/en.js';
import pagesSignalZhtw from '@/i18n/pages/signal/zh-tw.js';
import pagesSignalFa from '@/i18n/pages/signal/fa.js';
import pagesSignalJp from '@/i18n/pages/signal/jp.js';
import pagesSignalTh from '@/i18n/pages/signal/th.js';
import pagesSignalAr from '@/i18n/pages/signal/ar.js';
import pagesSignalHi from '@/i18n/pages/signal/hi.js';
import pagesSignalMs from '@/i18n/pages/signal/ms.js';

import pagesApplylogZhcn from '@/i18n/pages/applylog/zh-cn.js';
import pagesApplylogEn from '@/i18n/pages/applylog/en.js';
import pagesApplylogZhtw from '@/i18n/pages/applylog/zh-tw.js';
import pagesApplylogFa from '@/i18n/pages/applylog/fa.js';
import pagesApplyloglJp from '@/i18n/pages/applylog/jp.js';
import pagesApplyloglTh from '@/i18n/pages/applylog/th.js';
import pagesApplyloglAr from '@/i18n/pages/applylog/ar.js';
import pagesApplyloglHi from '@/i18n/pages/applylog/hi.js';
import pagesApplyloglMs from '@/i18n/pages/applylog/ms.js';

import pagesReportZhcn from '@/i18n/pages/report/zh-cn.js';
import pagesReportEn from '@/i18n/pages/report/en.js';
import pagesReportZhtw from '@/i18n/pages/report/zh-tw.js';
import pagesReportFa from '@/i18n/pages/report/fa.js';
import pagesReportlJp from '@/i18n/pages/report/jp.js';
import pagesReportlTh from '@/i18n/pages/report/th.js';
import pagesReportlAr from '@/i18n/pages/report/ar.js';
import pagesReportlHi from '@/i18n/pages/report/hi.js';
import pagesReportlMs from '@/i18n/pages/report/ms.js';

import pagesMoneyZhcn from '@/i18n/pages/money/zh-cn.js';
import pagesMoneyEn from '@/i18n/pages/money/en.js';
import pagesMoneyZhtw from '@/i18n/pages/money/zh-tw.js';
import pagesMoneyFa from '@/i18n/pages/money/fa.js';
import pagesMoneyJp from '@/i18n/pages/money/jp.js';
import pagesMoneyTh from '@/i18n/pages/money/th.js';
import pagesMoneyAr from '@/i18n/pages/money/ar.js';
import pagesMoneyHi from '@/i18n/pages/money/hi.js';
import pagesMoneyMs from '@/i18n/pages/money/ms.js';

import pagesProductIndexZhcn from '@/i18n/pages/productIndex/zh-cn.js';
import pagesProductIndexEn from '@/i18n/pages/productIndex/en.js';
import pagesProductIndexZhtw from '@/i18n/pages/productIndex/zh-tw.js';
import pagesProductIndexFa from '@/i18n/pages/productIndex/fa.js';
import pagesProductIndexJp from '@/i18n/pages/productIndex/jp.js';
import pagesProductIndexTh from '@/i18n/pages/productIndex/th.js';
import pagesProductIndexAr from '@/i18n/pages/productIndex/ar.js';
import pagesProductIndexHi from '@/i18n/pages/productIndex/hi.js';
import pagesProductIndexMs from '@/i18n/pages/productIndex/ms.js';

import errorEn from '@/i18n/pages/error/en.js';
import errorZhTw from '@/i18n/pages/error/zh-tw.js';
import errorZhcn from '@/i18n/pages/error/zh-cn.js';
import errorFa from '@/i18n/pages/error/fa.js';
import errorJp from '@/i18n/pages/error/jp.js';
import errorTh from '@/i18n/pages/error/th.js';
import errorAr from '@/i18n/pages/error/ar.js';
import errorHi from '@/i18n/pages/error/hi.js';
import errorMs from '@/i18n/pages/error/ms.js';


// 使用插件
Vue.use(VueI18n);

// 定义语言国际化内容
/**
 * 说明：
 * /src/i18n/lang 下的 js 为框架的国际化内容
 * /src/i18n/pages 下的 js 为各界面的国际化内容
 */
const messages = {
	zh: {
		...zhcnLocale,
		message: {
			...nextZhcn,			
		},
		...pagesHomeZhcn,
		...pagesLoginZhcn,
		...pagesCustomerZhcn,
		...adminmanagerZhcn,
		...pagesSignalZhcn,
		...pagesApplylogZhcn,
		...pagesReportZhcn,
		...pagesMoneyZhcn,
		...pagesProductIndexZhcn,
		...errorZhcn,
	},
	en: {
		...enLocale,
		message: {
			...nextEn,
		},
		...pagesHomeEn,
		...pagesLoginEn,
		...pagesCustomerEn,
		...adminmanagerEn,
		...pagesSignalEn,
		...pagesApplylogEn,
		...pagesReportEn,
		...pagesMoneyEn,
		...pagesProductIndexEn,
		...errorEn
	},
	// hk 繁体中文
	hk: {
		...zhtwLocale,
		message: {
			...nextZhtw,
		},
		...pagesHomeZhtw,
		...pagesLoginZhtw,
		...pagesCustomerZhtw,
		...adminmanagerZhtw,
		...pagesSignalZhtw,
		...pagesApplylogZhtw,
		...pagesReportZhtw,
		...pagesMoneyZhtw,
		...pagesProductIndexZhtw,
		...errorZhTw
	},
	// 波斯语
	fa: {
		...faLocale,
		message: {
			...nextFa,
		},
		...pagesHomeFa,
		...pagesLoginFa,
		...pagesCustomerFa,
		...adminmanagerFa,
		...pagesSignalFa,
		...pagesApplylogFa,
		...pagesReportFa,
		...pagesMoneyFa,
		...pagesProductIndexFa,
		...errorFa
	},
	jp: {
		...jpLocale,
		message: {
			...nextJp,
		},
		...pagesHomeJp,
		...pagesLoginJp,
		...pagesCustomerJp,
		...adminmanagerJp,
		...pagesSignalJp,
		...pagesApplyloglJp,
		...pagesReportlJp,
		...pagesMoneyJp,
		...pagesProductIndexJp,
		...errorJp
	},
	th: {//泰语
		...thLocale,
		message: {
			...nextTh,
		},
		...pagesHomeTh,
		...pagesLoginTh,
		...pagesCustomerTh,
		...adminmanagerTh,
		...pagesSignalTh,
		...pagesApplyloglTh,
		...pagesReportlTh,
		...pagesMoneyTh,
		...pagesProductIndexTh,
		...errorTh
	},
	ar: {//阿拉伯语
		...arLocale,
		message: {
			...nextAr,
		},
		...pagesHomeAr,
		...pagesLoginAr,
		...pagesCustomerAr,
		...adminmanagerAr,
		...pagesSignalAr,
		...pagesApplyloglAr,
		...pagesReportlAr,
		...pagesMoneyAr,
		...pagesProductIndexAr,
		...errorAr
	},
	hi: {//印地语
		//...hiLocale,
		message: {
			...nextHi,
		},
		...pagesHomeHi,
		...pagesLoginHi,
		...pagesCustomerHi,
		...adminmanagerHi,
		...pagesSignalHi,
		...pagesApplyloglHi,
		...pagesReportlHi,
		...pagesMoneyHi,
		...pagesProductIndexHi,
		...errorHi
	},
	ms: {//马来语
		//...msLocale,
		message: {
			...nextMs,
		},
		...pagesHomeMs,
		...pagesLoginMs,
		...pagesCustomerMs,
		...adminmanagerMs,
		...pagesSignalMs,
		...pagesApplyloglMs,
		...pagesReportlMs,
		...pagesMoneyMs,
		...pagesProductIndexMs,
		...errorMs
	},
};

// 导出语言国际化
export const i18n = new VueI18n({
	// silentTranslationWarn: true,  // 如果想要关闭全部由未翻译关键字造成的警告，
	locale: Local.get('themeConfigPrev')? Local.get('themeConfigPrev').globalI18n : store.state.themeConfig.themeConfig.globalI18n,
	fallbackLocale: 'zh',
	messages,
});
// console.log(i18n.tc("message.app.youLoggedOut")) 
// 重新封装方法
export function $t(args) {
  return i18n.tc.call(i18n, args);
}
