// 定义内容
export default {
	customer: {
		edit: "Edit",
		continue: "Continue",
		cancel: "Cancel",
		OK: "OK",
		account: {
			name: "Name",
			cellPhone: "Phone",
			country: "Country",
			province: "Province",
			city: "City",
			address: "Address",
			registrationDate: "Registration Time",
			account: "Account",
			noAccount: "No Account",
			clickToCreate: "Click to create"
		},
		bankcard: {
			payee: "Payee",
			SWIFTCode: "Swift Code",
			branchOfBankName: "Branch of bank name",
			bankAddress: "Bank Address",
			newBankProfile: "New Bank Profile",
			addBankInformation: "Add bank account information",
			editBankInformation: "Edit card information",
			bankName: "Bank",
			bankAccount: "Bank Account",
			branchName: "Branch",
		},
		certificate: {
			IDCardFrontPendingApproval: "ID card front is pending approval.",
			IDCardBackPendingApproval: "ID card back is pending approval.",
			BankCardPendingApproval: "Bank pending review",
			IDCardFrontApprovalFailed: "ID card front approval failed, Please re-upload the documents.",
			IDCardBackApprovalFailed: "ID card back approval failed, Please re-upload the documents.",
			BankCardApprovalFailed: "Bank card approval failed, Please re-upload the documents.",
			IDCardFront: "ID/Passport A",
			IDCardBack: "ID/Passport B",
			BankCard: "Proof Of Address",
			pictureFormatIncorrect: 'The picture format is incorrect',
      uploadMax3M: 'Upload profile picture size can not exceed 3MB!'
		},
		security: {
			security: "Security",
			email: "Email",
			googleAuthenticator: "Google Authenticator",
			transferSecuritySettings: "For Withdrawal, transfer,change of security settings.",
			loginPassword: "Login password",
			protectAccountSecurity: "To protect account security",
			bind: "Bind",
			modify: "Modify",
			resetCRMLoginPassword: "Reset the CRM login password",
			oldPassword: "Old Password",
			password: "Password",
			confirmPassword: "Confirm password",
			alter: 'Change'
		},
		google: {
			GATips: "GA(Google Authenticator) generates dynamic passwords and it is similar to SMS dynamic verification. Google Authenticator generates a new code every 30 seconds after you bind it to apps. This verification code can be used for withdrawal,transfer, and changing security settings to achieve maximum security.",
			DownloadGA: "Download GA",
			DownloadGATips: 'iOS users can log into App Store and search "Authenticator" to download. Android users can log into Google Play or search “Google Authenticator” in your mobile browser to download.',
			AddAndBackUpKeyInYourGA: "Add and back up key in your GA",
      OpenGAAddToken: "Open GA and scan the QR code below or enter the key to add a token.",
			CopyKey: "Copy key",
			SecretKeySaveTips: "This key is used to retrieve your GA when you change or lose your phone. Please save and back up the key before binding GA.",
			EnterThe6_digit: "Enter the 6-digit verification code in your GA",
			GACode: "GA Code",
			oldGoogleVerification: 'The original Google verification',
			newGoogleVerification: 'New Google Verification'
		}
	}
};
