// 定义内容
export default {
	apply: {
    Progress: "申請進度",
    comment: "備註",
    applyDate: "申請時間",
    applicationSuccess: "申請成功",
    applying: "申請中",
    applicationFailed: "申請失敗",
    productName: '產品名稱',
    registrationStatus: "狀態",
    registrationDate: "時間",
  }
};