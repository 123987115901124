// 定义内容
export default {
	customer: {
		edit: "編輯",
		continue: "提交",
		cancel: "取消",
		OK: "確定",
		account: {
			name: "姓名",
			cellPhone: "電話",
			country: "國籍",
			province: "省份",
			city: "城市",
			address: "地址",
			registrationDate: "註冊時間",
			account: "賬戶",
			noAccount: "無帳戶",
			clickToCreate: "去創建"
		},
		bankcard: {
			payee: "收款人",
			SWIFTCode: "Swift程式碼",
			branchOfBankName: "分支行名稱",
			bankAddress: "銀行地址",
			newBankProfile: "新增銀行",
			addBankInformation: "添加銀行卡信息",
			editBankInformation: "編輯銀行卡信息",
			bankName: "銀行名稱",
			bankAccount: "銀行卡號",
			branchName: "分行名稱",
		},
		certificate: {
			IDCardFrontPendingApproval: "身份證正面待審核",
			IDCardBackPendingApproval: "身份證反面待審核",
			BankCardPendingApproval: "銀行待審核",
			IDCardFrontApprovalFailed: "身份證正面審核失敗, 請重新上傳",
			IDCardBackApprovalFailed: "身份證反面審核失敗, 請重新上傳",
			BankCardApprovalFailed: "銀行卡審核失敗, 請重新上傳",
			IDCardFront: "身份證正面			",
			IDCardBack: "身份证反面",
			BankCard: "銀行卡證明正面",
			pictureFormatIncorrect: '圖片格式不正確',
      uploadMax3M: '上傳頭像圖片大小不能超過 3MB!'
		},
		security: {
			security: "賬戶安全",
			email: "郵箱",
			googleAuthenticator: "谷歌驗證器",
			transferSecuritySettings: "用於出金 轉賬、修改安全設置時進行安全驗證",
			loginPassword: "登入密碼",
			protectAccountSecurity: "用於保護賬戶安全",
			bind: "綁定",
			modify: "修改",
			resetCRMLoginPassword: "重置CRM登入密碼",
			oldPassword: "原密碼",
			password: "密碼",
			confirmPassword: "確認密碼",
			alter: '更改'
		},
		google: {
			GATips: "谷歌驗證器是一款動態口令工具，工作原理類似短信動態驗證。綁定後每30s生成一個動態驗證碼，驗證碼可用於提現、轉賬、修改安全設置等操作的安全驗證。",
			DownloadGA: "下載谷歌驗證器APP",
			DownloadGATips: "iOS用戶登錄App Store搜索“Authenticator”下載。安卓用戶登錄應用商店或使用手機瀏覽器搜索“谷歌驗證器”下載。",
			AddAndBackUpKeyInYourGA: "在谷歌驗證器中添加密鑰並備份",
      OpenGAAddToken: "打開谷歌驗證器，掃描下方二維碼或手動輸入下述密鑰添加驗證令牌。",
			CopyKey: "複製密鑰",
			SecretKeySaveTips: "密鑰用於手機更換或遺失找回谷歌驗證器，綁定前請務必將下述密鑰備份保存。",
			EnterThe6_digit: "輸入谷歌驗證器中6位驗證碼",
			GACode: "谷歌驗證碼",
			oldGoogleVerification: '原來的谷歌驗證',
			newGoogleVerification: '新的谷歌驗證'
		}
	}
};
