// 定义内容
export default {
	apply: {
    Progress: "申請の進捗",
    comment: "備考",
    applyDate: "申し込み時間",
    applicationSuccess: "申し込み成功",
    applying: "アプリケーション",
    applicationFailed: "アプリケーション失敗",
    productName: '製品名です',
    registrationStatus: "状態です",
    registrationDate: "時間です",
  }
};