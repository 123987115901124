// 定义内容
export default {
	app: {
		clientManagementSystem: '客戶管理系統',
		pleaseSelect: '請選擇',
		loginExpired: '登錄過期，請重新登錄',
		youLoggedOut: '你已被登出，請重新登錄',
		tips: '提示',
		total: '合計',
		noAccount: '無帳號',
		youHaveNoAccountYet: '您還沒有賬戶!',
		securityVerification: '安全驗證',
		emailVerificationCode: '郵件驗證碼',
		pleaseemailVerificationCode: '請輸入郵箱驗證碼',
		PleaseVerificationCode: '請輸入驗證碼',
		Send: '獲取驗證碼',
		Resend: '重新發送',
		cancel: '取消',
		OK: '確認',
		pleaseEnterBankName: '請輸入銀行名稱',
		pleaseEnterBankCard: '請輸入銀行卡號',
		pleaseEnterBranchName: '請輸入分行名稱',
		pleaseEnterBankAddress: '請輸入銀行地址',
		cannotBeEmpty: '不能爲空',
		pleaseEnterEmail: '請輸入郵箱',
		wrongAccountFormat: '錯誤的賬戶類別',
		pleaseEnterLastName: '請輸入姓',
		pleaseEnterFirstName: '請輸入名',
		pleaseEnterName: '請輸入姓名',
		pleaseEnterCellphoneNumber: '請輸入電話號碼',
		pleaseEnterTradingAccount: '請輸入交易賬戶',
		pleaseEnterSignalName: '請輸入信號名稱',
		pleaseEnterSignalCommission: '請輸入信號佣金',
		pleaseEnterReferralCommission: '請輸入推薦人佣金',
		pleaseEnterOldPassword: '請輸入原密碼',
		PleaseEnterPassword: '請輸入密碼',
		passwordLength6and16: '密碼長度為6到16位之間',
		newPasswordsDoNotMatch: '兩次輸入的密碼不一致!',
		pleaseEnterRechargeAmount: '請輸入充值金額',
		pleaseEnterWithdrawalAmount: '請輸入出金金額',
		notSufficientFunds: '餘額不足',
		pleaseEnterProvinces: '請輸入省份',
		pleaseEnterCity: '請輸入都市',
		pleaseEnterAddress: '請輸入地址',
		pleaseSelectAnAreaCode: '請選擇區號',
		pleaseSelectNationality: '請選擇國籍',
		justNow: '剛剛',
		aecondsAgo: '秒前',
		minuteAgo: '分鐘前',
		hoursAgo: '小時前',
		daysAgo: '天前',
	},
	EA: {
		EA: 'EA',
		Indicators: '指標',
		free: '免費',
		Free: '免費',
		download: '下載',
		more: '更多',
	},
	score: {
		clockInAlready: '已簽到',
		notEnoughScore: '積分不足',
		myScore: '我的積分',
		getIntegral: '領積分',
		useIntegral: '玩轉積分',
		signedIn: '已簽到',
		integralThreeMonthsStatistics: '最近三個月積分統計',
		CheckInScore: '簽到積分',
		other: '其他',
		pointsDraw: '積分抽獎',
		spend: '消耗',
		pointToGetALuckyDraw: '積分可獲得一次抽獎機會',
		youHave: '您還有',
		moreLuckyDraws: '次抽獎機會',
		prizesCostPoints: '抽獎消耗積分',
		integralDetail: '積分明細',
		time: '時間',
		income_expenditure: '收入/支出',
		remark: '備註',
		Sign_in: '簽到',
		trading: '交易',
		payment: '支付',
		rewardPointsOrders: '大額訂單獎勵積分',
		developCustomers: '發展客戶',
		developCustomerDeposits: '發展客戶入金',
		prizeDraw: '抽獎',
		exchangeGoods: '兌換物品',
		expired: '過期',
		donate: '捐贈',
		congratulationsYourWin: "恭喜你獲得了",
		drawRecord: '抽獎記錄',
		prize: '獎品'
	},
	notice: {
		title: '標題',
		time: '日期',
	},
	openwindow: {
		IBReferralLink: 'IB推廣鏈接',
		copyReferralLink: '點擊複製推廣鏈接',
	},
	link: {
		GoExperienceNow: '立即前往體驗',
		BackHomePage: '頁面"回到首頁"已在新窗口中打開',
	},
	bankinfo: {
		collectionInformation: '收款信息',
		amount: '金額',
		order: '訂單號',
		currencyOfPayment: '支付幣種',
		bankName: '銀行名稱',
		bankAccount: '銀行帳戶',
		bankAddress: '銀行地址',
		swiftCode: 'Swift程式碼',
		Payee: '收款方',
		paymentAmount: '支付金額',
		two_dimensionalCode: '二維碼',
		uploadvoucher: '上傳憑證',
		close: '關閉',
	},
	init: {
		improveBasicPersonalInformation: '完善個人基本信息',
		submitDocumentInformation: '提交證件信息',
	},
	highcharts: {
		chartExportMenu: '圖表導出菜單',
		fullScreenView: '全屏查看',
		exitFullScreen: '退出全屏',
		printChart: '打印圖表',
		downloadJPEGImages: '下載JPEG圖片',
		downloadPDFFile: '下載PDF文件',
		downloadPNGFile: '下載PNG文件',
		downloadSVGFile: '下載SVG文件',
	},
	router: {
		init: '完善信息',
		home: '主頁',
		customer: '個人信息',
		accountInformation: '賬戶信息',
		bankCard: '銀行信息',
		certificateInformation: '證件信息',
		accountSecurity: '賬戶安全',
		accountSecurityBindga: '谷歌驗證器',
		adminmanager: '我的賬戶',
		leverageModify: '調整槓桿',
		customermana: '客戶管理',
		customerRelationship: '客戶關係',
		signal: '跟單社區',
		signalList: '信號源',
		signalDetails: '訊號詳情',
		myFollowSignal: '我的跟單',
		userSignal: '我的信號源',
		applyLog: '申請記錄',
		accountApplyLog: '帳戶申請記錄',
		followSignalLog: '跟單申請記錄',
		signalApplyLog: '信號源申請記錄',
		reportCenter: '報表中心',
		closingReport: '平倉報告',
		positionReport: '持倉報告',
		performanceReport: '業績報表',
		commissionStatistics: '傭金統計',
		commissionReport: '佣金報告',
		devidenReport: '利潤分紅',
		moneyManagement: '資金管理',
		onlineDeposit: '充值',
		transelectronic: '電匯信息',
		withdrawalApply: '提現',
		accountTransfer: '帳戶轉帳',
		balanceHistory: '錢包',
		Experts: 'EA',
		Indicators: '指標',
		ExplainEA: '安裝指南',
		score: '積分',
		myScore: '我的積分',
		promotionLink: '推廣鏈接',
		layoutLinkView: '外鏈',
		backToFrontPage: '回到首頁',
		productIndex: '產品',
		noticeIndex: '公告',
		noticeDetails: '公告詳情',	
		product: '產品管理',
		myProduct: '持有產品',
		productBuy: '買入記錄',
		productSell: '買出記錄',		
		details: '詳情'
	},
	staticRoutes: {
		signIn: '登錄',
		notFound: '找不到此頁面',
		noPower: '沒有許可權',
	},
	user: {
		title0: '組件大小',
		title1: '語言切換',
		title2: '選單蒐索',
		title3: '佈局配寘',
		title4: '消息',
		title5: '開全屏',
		title6: '關全屏',
		dropdownDefault: '默認',
		dropdownMedium: '中等',
		dropdownSmall: '小型',
		dropdownMini: '超小',
		home: '首頁',
		modifyPassword: '修改密碼',
		profilePhoto: '修改頭像',
		logOut: '退出登錄',
		uploadPhoto: '上傳頭像',
		confirm: '確認修改',
		searchPlaceholder: '選單蒐索：支援中文、路由路徑',
		newTitle: '通知',
		newBtn: '全部已讀',
		newGo: '前往通知中心',
		newDesc: '暫無通知',
		logOutTitle: '提示',
		logOutMessage: '此操作將登出，是否繼續？',
		logOutConfirm: '確定',
		logOutCancel: '取消',
		logOutExit: '退出中',
		logOutSuccess: '安全退出成功！',
	},
	tagsView: {
		refresh: '重繪',
		close: '關閉',
		closeOther: '關閉其它',
		closeAll: '全部關閉',
		fullscreen: '當前頁全屏',
	},
	notFound: {
		foundTitle: '地址輸入錯誤，請重新輸入地址~',
		foundMsg: '您可以先檢查網址，然後重新輸入或給我們迴響問題。',
		foundBtn: '返回首頁',
	},
	noAccess: {
		accessTitle: '您未被授權，沒有操作許可權~',
		accessBtn: '重新授權',
	},
	layout: {
		configTitle: '佈局配寘',
		oneTitle: '全域主題',
		twoTitle: '選單 / 頂欄',
		twoTopBar: '頂欄背景',
		twoMenuBar: '選單背景',
		twoColumnsMenuBar: '分欄選單背景',
		twoTopBarColor: '頂欄默認字體顏色',
		twoMenuBarColor: '選單默認字體顏色',
		twoColumnsMenuBarColor: '分欄選單默認字體顏色',
		twoIsTopBarColorGradual: '頂欄背景漸變',
		twoIsMenuBarColorGradual: '選單背景漸變',
		twoIsMenuBarColorHighlight: '選單字體背景高亮',
		threeTitle: '介面設定',
		threeIsCollapse: '選單水准折疊',
		threeIsUniqueOpened: '選單手風琴',
		threeIsFixedHeader: '固定 Header',
		threeIsClassicSplitMenu: '經典佈局分割選單',
		threeIsLockScreen: '開啟鎖屏',
		threeLockScreenTime: '自動鎖屏(s/秒)',
		fourTitle: '介面顯示',
		fourIsShowLogo: '側邊欄 Logo',
		fourIsBreadcrumb: '開啟 Breadcrumb',
		fourIsBreadcrumbIcon: '開啟 Breadcrumb 圖標',
		fourIsTagsview: '開啟 Tagsview',
		fourIsTagsviewIcon: '開啟 Tagsview 圖標',
		fourIsCacheTagsView: '開啟 TagsView 緩存',
		fourIsSortableTagsView: '開啟 TagsView 拖拽',
		fourIsFooter: '開啟 Footer',
		fourIsGrayscale: '灰色模式',
		fourIsInvert: '色弱模式',
		fourIsDark: '深色模式',
		fourIsWartermark: '開啟浮水印',
		fourWartermarkText: '浮水印文案',
		fiveTitle: '其它設定',
		fiveTagsStyle: 'Tagsview 風格',
		fiveAnimation: '主頁面切換動畫',
		fiveColumnsAsideStyle: '分欄高亮風格',
		fiveColumnsAsideLayout: '分欄佈局風格',
		sixTitle: '佈局切換',
		sixDefaults: '默認',
		sixClassic: '經典',
		sixTransverse: '橫向',
		sixColumns: '分欄',
		tipText: '點擊下方按鈕，複製佈局配寘去`src/store/modules/themeConfig.js`中修改。',
		copyText: '一鍵複製配寘',
		resetText: '一鍵恢復默認',
		copyTextSuccess: '複製成功！',
		copyTextError: '複製失敗！',
	},
	upgrade: {
		title: '新版本陞級',
		msg: '新版本來啦，馬上更新嘗鮮吧！ 不用擔心，更新很快的哦！',
		desc: '提示：更新會還原默認配寘',
		btnOne: '殘忍拒絕',
		btnTwo: '馬上更新',
		btnTwoLoading: '更新中',
	},
};
