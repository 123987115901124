export default {
  product: {
    annualization: 'Annualized Yields',
    retract: 'Max Drawdown',
    riskType: 'Risk Type',
    radicalType: 'Radical Type',
    balancedType: 'Balanced Type',
    conservativeType: 'Conservative Type',
    timeLimit: 'Duration',
    managementFee: 'Management Fee',
    productAmount: 'Product Amount',
    buy: 'Buy',
    day: 'Day',
    details: {
      details: 'Details',
      product: 'Name',
      lever: 'Leverage',
      group: 'Gruop',
      productDescription: 'Description',
    }
  }
};