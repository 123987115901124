// 定义内容
export default {
	signal: {
    tips: "提示",
    agree: "同意",
    refuse: "拒绝",
    OK: "确定",
    cancel: "取消",
    Submit: "提交申请",
    signalName: "信号名称",
    equity: "净值",
    balance: "余额",
    operation: "操作",
    direction: "跟单方向",
    positive: "正向跟单",
    reverse: "反向跟单",
    tradeType: "跟单类型",
    percentage: "跟单手数百分比",
    fixedLots: "固定手数",
    equityPercentage: "净值比例",
    list: {
      all: "全部",
      proSignal: "公共信号",
      socialSignal: "私域信号",
      monthlyProfit: "月化(%)",
      currentProfit: "当前盈利(%)",
      dailyProfit: "日盈利率(%)",
      maxDrawdown: "最大回撤(%)",
      leverage: "杠杆",
      profit: "获利",
      lots: "手数",
      weeklyProfit: "近一周收益",
      applyForCopy: "申请跟随",
      tips_text: "您正在进行信号跟单操作，如您选择“同意”则表示您已知悉并同意接受以下信息内容",
      tips_text1: "本人已知悉跟随信号账户进行交易, 其相关参数设置属于个人自主行为, 完全知悉此操作存在的交易风险，并自愿承担跟随信号账户交易所导致的账户盈亏的一切结果, 与他人无关。",
      tips_text2: "本人已知悉跟随信号账户进行跟单交易的规则和收费标准，同意从本人跟单账户中扣除相关跟单操作所产生的信号订阅相关费用。",
      tips_text3: "本人已知悉跟单社区信号源中的信号账户是由广大交易爱好者提供, XXXX不对任何信号账户的交易结果和风险负责。",
      applyTrade: "申请跟单",
      applyTradeTips: "提示：跟单账号不能重复跟单，且跟单账号不能是信号",
      tradeLogin: "跟单账号",      
      tradeValue: "跟单类型值",
      percentageLeast1: "跟单手数百分比最少为1",
      fixedLotsLeast1: "固定手数最少为0.01"
    },
    myfollow: {
      tradingAccount: "交易账号",
      signalState: "信号状态",
      Normal: "正常",
      Unavailable: "不可用",
      creationTime: "创建时间",
      unSubscribe: "撤销跟单",
      applyForUnsubscribe: "已提交撤离跟单申请",
    },
    userSignal: {
      createSignal: "申请成为信号",
      tips_text1: "信号账户余额低于100$，且在一周之内无增加资金、无交易、无跟单的信号账号会被跟单社区系统下架",
      tips_text2: "涉嫌使用违规交易手段进行恶意套利的信号账户， 将会被冻结30个自然日，信号账户冻结期间，不能进行MT4及CRM系统登录；信号账户冻结期间，账户中的持仓订单盈亏由信号账户持有人自行承担。",
      tips_text3: "确认使用违规交易手段进行恶意套利的信号账户，其信号账户和同名账户将会被永久封号，信号账户持有人将被列入信号黑名单及禁止在XXXXX进行开户交易。其信号账户封号期间的持仓订单盈亏由信号账户持有人自行承担。",
      MT4Account: "MT4账户",
      profitShareAccount: "分红账号",
      signalCommissions: "信号佣金",
      referralCommission: "推荐人佣金",
      tradeMethod: "交易方式",
      manual: "手动",
      other: "其他",
      percentage: "百分比",
      applyDate: "申请时间",
      comment: "备注",
      dataUpdateTime: "数据更新时间",
      disabledSignal: "设置为禁用",
      delete: "删除",
      activateSignal: "申请启用",
      applying: "申请中",
      noIdleAccount: "没有空闲的账号"
    },
    details: {
      balanceDetails: "资金走势图",
      fundBalance: "资金",
      equityDetails: "净值走势图",
      deposit: "入金",
      netProfit: " 净利润",
      profitRatio: "盈利率",
      profitOrders: "盈利订单",
      lossOrders: "亏损订单",
      trades: "订单个数",
      signalDetail: "信号详情",
      totalDeposit: "总入金",
      totalWithdrawal: "总出金",
      profit: "盈利",
      loss: "亏损",
      profitRatioNumber: "盈利系数",
      volume: "交易量",
      orderQuantity: "订单数量",
      longPositionOrders: "多单数量",
      shortPositionOrders: "空单数量",

      signalIntroduction: "信号简介",
      dataStatistics: "数据统计",
      profitFactor: "胜率分析",
      symbolsStatistics: "交易品种统计",

      orderDetails: "订单详情",
      type: "类型",
      lots: "手数",
      symbols: "交易品种",
      openTime: "开仓时间",
      openPrice: "开仓价格",
      stopLoss: "止损",
      takeProfit: "止盈",
      closeTime: "平仓时间",
      closePrice: "平仓价格",
      commission: "手续费",
      swap: "库存费",
      tableProfit: "获利",
      tradeProfile: "交易概况",
      volume: "总交易量",
      tradingDays: "交易天数",
      day: "天",
      maxDrawdown: "最大回撤",
      weeklyProfit: "近一周收益",
      monthlyProfit: "近一月收益",
      totalProfit: "总收益"
    }
  }
};
