// 波斯语
export default {
	netWorkError: 'خطای شبکه',
	wrongNameOrPassword: 'خطای نام کاربر یا رمز‌گذاری',
	forbiddenToLogin: 'ناتوان ورود',
	success: 'موفقیت',
	emailFormatError: 'خطای قالب الکترونیک',
	pwdLengthError: 'طول رمز‌گذاری باید 6- 18 شخصیت باشد',
	pwdStrongError: 'رمز‌گذاری باید شامل شخصیت‌ها و نمره‌ها باشد',
	mailAlreadyExists: 'این ایمیل ثبت شده، لطفاً آدرس ایمیل جدید را تغییر دهید',
	registerSuccess: '!ایمیل با موفقیت فرستاده شده است، لطفا برید به ایمیل شما تا تایید کنید',
	registerFailed: 'شکست ثبت، لطفا با خدمت مشتری تماس بگیرید',
	activeExpired: 'گواهی حساب ، لطفاً دوباره امتحان کنید',
	activeSuccess: 'بررسی حساب موفق شد',
	sendMailSuccess: 'ایمیل موفقیت فرستاده شده است',
	accountDisabled: 'حساب فعال شده است',
	sendMailFailure: 'نامه‌ی ارسال شکست خورده است، لطفا با خدمت مشتری تماس بگیرید',
	emailNotExist: 'نشانی ایمیل وجود ندارد',
	setPasswordSuccess: 'تنظیم رمز‌گذاری موفقیت',
	setPasswordFailureTryAgain: 'تغییر رمز گذاری شکست خورد ، لطفاً دوباره امتحان کنید',
	oldPwdNotMatch: 'رمز اصلی هماهنگ نیست',
	failedTryAgain: 'عملیات شکست خورد ، لطفاً دوباره امتحان کنید',
	errorInput: 'اطلاعات ورودی اشتباه است، لطفاً دوباره بررسی کنید',
	signalHasPositions: 'منبع سیگنال یک موقعیت دارد',
	hasSubscription: '!سیگنال حساب‌های پیروی دارد، نمی‌تواند عملیات را کامل کند',
	alreadySubmitUnScribe: 'فرستادن کاربرد ناپیوند قبلا',
	closePositionsFirst: 'لطفاً تمام موقعیت بازی‌ها را ببندید',
	loginNotExist: 'شماره حساب وجود ندارد',
	alreadyWasSignal: 'این حساب قبلاً برای کاربرد سیگنال است',
	alreadyCopyTrade: 'حالا کاپیتان تجارت',
	alreadyFollowTheSignal: 'کپی سیگنال قبلا',
	thisLoginSubmitSignalApply: 'کاربرد سیگنال ارسال شده است',
	alreadySubmitCopyApply: 'کپی کاربرد تجارت فرستاده شده است',
	noBankCard: 'لطفا بانک خواننده را اضافه کنید',
	verifyExpired: "تأیید انجام نشد",
	notEnoughMoney: "تعادل موجود کافی نیست",
	subscribersOnlyWithdrawOnSunday: "!این حساب زیر نیاز به فرستادن کاربرد کشیده در یکشنبه است",
	maxMinDeposit: "اندازه ذخیره",
	minWithdrawal: "حداقل مبلغ برداشت نقدی",
	dailyWithdrawalsLimit: "!برداشتن پول به حداکثر روز رسیده است",
	doNotRepeatUpload: "لطفاً دوباره فرستادن نکنید",
	alreadyMaxCount: 'تعداد حساب به حد بیشتری رسید',
	loginTypeError: "خط حساب اشتباه",
	loginOutOfRange: "!شماره حساب بیشتر از محدوده بخش",
	codeSendSuccess: "!رمز تأیید فرستاده موفقیت",
	gaVerifyFailed:"تایید هویت Google شکست خورد",
	keepOneVerifyAtLeast:"حداقل یک تایید امنیتی داشته باشید",
	notEnoughScore:"نقاط ناکافی",
	clockInAlready:"ساعت در",
	demoOpenSuccess:"حساب ازمایشی با موفقیت باز می شود، لطفا برای بررسی ایمیل توجه کنید"
};
