// 定义内容
export default {
	customer: {
		edit: "編集する",
		continue: "提出する",
		cancel: "キャンセル",
		OK: "確定です",
		account: {
			name: "お名前",
			cellPhone: "電話番号",
			country: "国籍",
			province: "地方",
			city: "市区町村",
			address: "住所",
			registrationDate: "登録時間",
			account: "アカウント",
			noAccount: "アカウントなし",
			clickToCreate: "作成する"
		},
		bankcard: {
			payee: "受取人",
			SWIFTCode: "迅速なコード",
			branchOfBankName: "支線名",
			bankAddress: "銀行住所",
			newBankProfile: "新しい銀行",
			addBankInformation: "銀行カード情報を追加する",
			editBankInformation: "カード情報を編集します",
			bankName: "銀行名",
			bankAccount: "銀行カード番号",
			branchName: "支店名",
		},
		certificate: {
			IDCardFrontPendingApproval: "IDカードは審査待ちです",
			IDCardBackPendingApproval: "IDカードは審査待ちです",
			BankCardPendingApproval: "銀行審査待ち",
			IDCardFrontApprovalFailed: "IDカードの肯定的なレビューに失敗しました, 再アップロードしてください",
			IDCardBackApprovalFailed: "IDカードの否定的なレビューに失敗しました, 再アップロードしてください",
			BankCardApprovalFailed: "銀行カードの確認に失敗しました, 再アップロードしてください",
			IDCardFront: "パスポート			",
			IDCardBack: "パスポート",
			BankCard: "銀行カードプルーフプラス",
			pictureFormatIncorrect: '画像フォーマットが正しくありません。',
      uploadMax3M: '画像の大きさは3MBを超えてはいけません!'
		},
		security: {
			security: "安全保障",
			email: "Eメール",
			googleAuthenticator: "Googleオーセンティケーター",
			transferSecuritySettings: "資金の引き出しと送金、セキュリティ設定の変更時にセキュリティ検証に使用されます",
			loginPassword: "ログインパスワード",
			protectAccountSecurity: "アカウントのセキュリティを保護するために使用されます",
			bind: "バインド",
			modify: "パスワードを変更する",
			resetCRMLoginPassword: "CRMログインパスワードをリセットする",
			oldPassword: "旧パスワード",
			password: "パスワード",
			confirmPassword: "パスワード確認",
			alter: '変更します'
		},
		google: {
			GATips: "Google認証システムは、SMS動的認証のように機能する動的パスワードツールです。 バインド後、動的検証コードが30秒ごとに生成されます。検証コードは、セキュリティ設定の取り消し、転送、変更などの操作のセキュリティ検証に使用できます。",
			DownloadGA: "Google Validator APPをダウンロード",
			DownloadGATips: "iOSユーザーはApp Storeにログインし、「Authenticator」を検索してダウンロードします。 Androidユーザーはアプリストアにログインするか、モバイルブラウザーを使用して「Google Authenticator」を検索し、ダウンロードします。",
			AddAndBackUpKeyInYourGA: "Google認証システムでキーとバックアップを追加します",
      OpenGAAddToken: "Google認証システムを開き、以下のQRコードをスキャンするか、次のキーを手動で入力して確認トークンを追加します。",
			CopyKey: "写す",
			SecretKeySaveTips: "このキーは、電話を交換したり、紛失した場合にGoogle Authenticatorを取得したりするために使用されます。バインドする前に、必ず次のキーをバックアップしてください。",
			EnterThe6_digit: "Google認証システムからの6桁の確認コードを入力してください",
			GACode: "Google確認コード",
			oldGoogleVerification: '元のグーグル検証です',
			newGoogleVerification: 'グーグル検証です',
		}
	}
};
