// 简体中文
export default {
	netWorkError: '网络错误',
	wrongNameOrPassword: '用户名或密码错误',
	forbiddenToLogin: '禁止登录',
	success: 'success',
	emailFormatError: '邮箱格式不正确',
	pwdLengthError: '密码长度为6--18位',
	pwdStrongError: '密码必须包含数字和大小写字母',
	mailAlreadyExists: '账户已经注册,请更换邮箱',
	registerSuccess: '邮件发送成功,请前往邮箱验证!',
	registerFailed: '注册失败,请联系客服',
	activeExpired: '账户验证失败,请重新尝试',
	activeSuccess: '账户验证成功',
	sendMailSuccess: '邮件发送成功',
	accountDisabled: '账户已禁用',
	sendMailFailure: '邮件发送失败,请联系客服',
	emailNotExist: '邮箱地址不存在',
	setPasswordSuccess: '密码重置成功',
	setPasswordFailureTryAgain: '密码重置失败,请重新尝试',
	oldPwdNotMatch: '旧密码不匹配',
	failedTryAgain: '操作失败,请重新尝试',
	errorInput: '输入的信息有误,请检查',
	signalHasPositions: '信号源有持仓',
	hasSubscription: '信号源有跟单账户，无法完成操作!',
	alreadySubmitUnScribe: '已经提交了撤离申请',
	closePositionsFirst: '请关闭所有持仓订单',
	loginNotExist: '账号不存在',
	alreadyWasSignal: '已经是信号源',
	alreadyCopyTrade: '正在跟单',
	alreadyFollowTheSignal: '已经在跟当前信号',
	thisLoginSubmitSignalApply: '已经提交信号申请',
	alreadySubmitCopyApply: '已经提交跟单申请',
	noBankCard: '请添加收款银行',
	verifyExpired: '验证失败',
	notEnoughMoney: '可用余额不足',
	subscribersOnlyWithdrawOnSunday: '跟单用户请在每周周日提交出金申请!',
	maxMinDeposit: '最低充值金额:',
	minWithdrawal: '最低提现金额:',
	dailyWithdrawalsLimit: '提现达到单日最高限额!',
	doNotRepeatUpload: '请勿重复上传',
	alreadyMaxCount: '账户数量已达到上限',
	loginTypeError: "错误的账户类别",
	loginOutOfRange: "账户超出范围!",
	codeSendSuccess: "验证码发送成功!",
	gaVerifyFailed:"谷歌身份验证失败",
	keepOneVerifyAtLeast:"至少保留一项安全验证",
	notEnoughScore:"积分不足",
	clockInAlready:"已签到",
	demoOpenSuccess:"模拟账户开设成功，请注意查收邮件"
};
