// 定义内容
export default {
	adminmanage: {
    myAccount: {
      leverage: "レバレッジ",
      equity: "正味額",
      balance: "バランス",
      credit: "クレジット",
      freeMargin: "利用可能なマージン",
      spentMargin: "使用済み保証金",
      buyTime: '時間を買います',
      buy: '買います',
      sell: '売ります',
      myProduct: '私の製品です',
      profitRate: '利益率です',
      details: '詳細です',
      history: '歴史です'
    },
    details: {
      profitChart: '利益のグラフです',
      profit: '利益です',
      equityChart: '純価値グラフです',
    }
  }
};