// 定义内容
export default {
	money: {
    currencySelect: "Currency Select",
    comment: "Comment",
    name: "Name",
    withdrawalAmount: "Withdrawal amount",
    continue: "Continue",
    balanceAccount: "Balance account",
    transactionAccount: "Trading account",
    commissionAccount: "Commission account",
    deposit: {
      paymentMethod: "Payment Method",
      BankWire: "Bank Wire",
      depositAmount: "Amount paid",
      currentExchangeRate: "Current Exchange Rate",
      dollars: "Deposit amount",
      payment: "Payment",
    },
    transelectronic: {
      bankName: "Bank",
      bankAccount: "Bank Account",
      SWIFT: "Swift Code",
      bankAddress: "Bank Address",
      receivables: "Payee",
    },
    withdrawal: {
      availableBalance: "Available balance",
      bankInformation: "Bank Information",
    },
    accountTransfer: {
      from: "from",
      to: "to",
      account: "Account",
      amount: "Amount",
      fullTransfer: "Full transfer",
      maximumRotatable: "Maximum rotatable",
      transferAccount_pleasePositions: "To transfer funds from the trading account, please close all the positions of the account first",
    },
    balancehistory: {
      startTime: "Start time",
      endTime: "End time",
      order: "Order",
      amount: "Amount($)",
      MT4Account: "MT4 Account",
      date: "Date",
      state: "State",
      annotation: "Comment",
      income: "income",
      rollOut: "Transfer out",
      shiftTo: "shift to",
      withdrawal: "Withdrawal",
      recharge: "Deposit",
      balanceAccount: "Balance account",
      transactionAccount: "Transaction account",
      commissionAccount: "Commission account",
      transfer: "Transfer",
      account: 'account',
      balance: 'Balance',
      allAccounts: "All accounts",
      totalAssets: "Total assets",
    }
  }
};
