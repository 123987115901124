// 定义内容
export default {
	report: {
    startTime: "開始時間です",
    endTime: "終了時間です",
    order: "ご注文",
    account: "アカウント",
    type: "タイプ",
    lots: "手の数",
    symbols: "取引の多様性",
    stopLoss: "ストップロス",
    takeProfit: "利益を取る",
    openTime: "営業時間",
    openPrice: "開倉價格",
    closePrice: "終値",
    closeTime: "閉店時間",
    commissionPrice: "手数料",
    profit: "利益",
    total: "合計です",
    closing: {
      swap: "在庫料",
    },
    position: {
      time: "時間",
      price: "価格",
      currentPrice: "現在の価格",
      swap: "一晩の関心",
    },
    performance: {
      name: "お名前",
      date: "日付",
      comment: "コメント",
      amount: "金额",
    },
    commission: {
      account_order: "アカウント/注文番号",
      MT4account: "トレーディング口座",
      type: "取引タイプ",
      volume: "単一数量",
      Commission: "リベート"
    },
    deviden: {
      comment: "コメント",
      time: "時間",
      profit: "利益です"
    }
  }
};
