// 定义内容
export default {
	money: {
    currencySelect: "通貨の選択",
    comment: "備考",
    name: "お名前",
    withdrawalAmount: "出金額",
    continue: "提出する",
    balanceAccount: "残高口座です",
    transactionAccount: "取引口座です",
    commissionAccount: "コミッション口座です",
    deposit: {
      paymentMethod: "お支払い方法",
      BankWire: "Bank Wire",
      depositAmount: "支払い金額です",
      currentExchangeRate: "現在の為替レート",
      dollars: "ご入金額です",
      payment: "今すぐ支払う",
    },
    transelectronic: {
      bankName: "銀行名",
      bankAccount: "銀行口座番号",
      SWIFT: "迅速なコード",
      bankAddress: "銀行住所",
      receivables: "受取人",
    },
    withdrawal: {
      availableBalance: "利用可能残高",
      bankInformation: "銀行情報",
    },
    accountTransfer: {
      from: "ですから",
      to: "着きました",
      account: "アカウント",
      amount: "金額です",
      fullTransfer: "全部回転します",
      maximumRotatable: "回すことができます",
      transferAccount_pleasePositions: "引口座から資金を振り替えて、まず当該口座のポジションをすべて平準化してください。",
    },
    balancehistory: {
      startTime: "開始時間です",
      endTime: "終了時間です",
      order: "注文番号",
      amount: "金額（$）",
      MT4Account: "MT4アカウント",
      date: "日付",
      state: "州",
      annotation: "コメント",
      income: "収入です",
      rollOut: "転出します",
      shiftTo: "転入します",
      withdrawal: "現金で出す",
      recharge: "チャージ",
      balanceAccount: "残高口座です",
      transactionAccount: "取引口座です",
      commissionAccount: "コミッション口座です",
      transfer: "回転します",
      account: '口座です',
      balance: '残額です',
      allAccounts: "すべての口座です",
      totalAssets: "総資産です",
    }
  }
};
