const VUE_MODE = '';

let baseUrl = '';
let socketUrl = '';
let socketSingle = '';
let outTime = 0;

if (VUE_MODE === 'development') {
	//开发环境下的执行操作
	baseUrl = 'http://192.168.1.24:8095/';
	socketUrl = 'ws://154.19.203.191:10600/quotelast';
	socketSingle = 'ws://154.19.203.191:10600/quotesingle';
	outTime = 1000 * 60 * 1;
} else if (VUE_MODE === 'production') {
	baseUrl = 'https://api.mt4ea.io/';
	socketUrl = 'ws://148.72.215.218:10600/quote';
	socketSingle = 'ws://148.72.215.218:10600/quotesingle';
	outTime = 1000 * 60 * 1;
} else {
	//生产环境
	baseUrl = 'https://api.matchtofund.com/';
	socketUrl = 'ws://8.222.143.186:10600/quote';
	socketSingle = 'ws://8.222.143.186:10600/quotesingle';
	outTime = 1000 * 60 * 1;
}

export default {
	terminal: 2, //0:Crm  1:Trade   2:Crm&Trade
	chart: 2, // 1.TradingView 2. kline
	showSignal: true,
	apiUrl: baseUrl,
	socketPath: socketUrl,
	socketForSingle: socketSingle,
	timeOut: outTime,
	// imageUrl: "https://files.mt5ea.io/fileupload/index",
	// imageBase64Url: "https://files.mt5ea.io/fileupload/filebase64",
	imageUpload: 'https://files.matchtofund.com/index.php?explorer/upload/fileUpload',

	//===================   KLine Chart ============================//
	//dark
	textColorDark: '#929AA5',
	gridColorDark: '#292929',
	axisLineColorDark: '#333333',
	crossTextBackgroundColorDark: '#373a40',
	//light
	textColorLight: '#76808F',
	gridColorLight: '#ededed',
	axisLineColorLight: '#DDDDDD',
	crossTextBackgroundColorLight: '#686d76',

	backgroundColorDark: '#1f2126',

	//https://en.wikipedia.org/wiki/List_of_tz_database_time_zones#List
	//timeZone: "Atlantic/Azores",//夏令时
	timeZone: 'Europe/London', //冬令时
	kLineTheme: 'light', //dark or light

	//===================End   KLine Chart =========================//

	profitDaily: 0.2, //日盈利率
};
