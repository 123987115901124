// 定义内容
export default {
	app: {
		clientManagementSystem: 'Client Management System',
		pleaseSelect: 'Please select',
		loginExpired: 'Login expired, please login again',
		youLoggedOut: 'You have been logged out. Please log in again',
		tips: 'Tips',
		total: 'Total',
		noAccount: 'No account',
		youHaveNoAccountYet: 'You have no account yet!',
		securityVerification: 'Verification',
		emailVerificationCode: 'Email verification code',
		pleaseemailVerificationCode: 'Please enter your email verification code',
		PleaseVerificationCode: 'Please enter the verification code',
		Send: 'Get Code',
		Resend: 'Resend',
		cancel: 'Cancel',
		OK: 'OK',
		pleaseEnterBankName: 'Please enter the bank name',
		pleaseEnterBankCard: 'Please enter your bank card number',
		pleaseEnterBranchName: 'Please enter a branch name',
		pleaseEnterBankAddress: 'Please enter the bank address',
		cannotBeEmpty: 'Cannot be empty',
		pleaseEnterEmail: 'Please enter email',
		wrongAccountFormat: 'Wrong account format',
		pleaseEnterLastName: 'Please enter last name',
		pleaseEnterFirstName: 'Please enter first name',
		pleaseEnterName: 'Please enter the name',
		pleaseEnterCellphoneNumber: 'Please enter the cellphone number',
		pleaseEnterTradingAccount: 'Please enter your trading account',
		pleaseEnterSignalName: 'Please enter the signal name',
		pleaseEnterSignalCommission: 'Please enter the signal commission',
		pleaseEnterReferralCommission: "Please enter referrer's commission",
		pleaseEnterOldPassword: 'Please enter the old password',
		PleaseEnterPassword: 'Please enter your password',
		passwordLength6and16: 'The length of the password is between 6 and 16 bits',
		newPasswordsDoNotMatch: 'New passwords do not match',
		pleaseEnterRechargeAmount: 'Please enter the recharge amount',
		pleaseEnterWithdrawalAmount: 'Please enter the withdrawal amount',
		notSufficientFunds: 'Sorry, your credit is running low.',
		pleaseEnterProvinces: 'Please enter the provinces.',
		pleaseEnterCity: 'Please enter the city.',
		pleaseEnterAddress: 'Please enter the address.',
		pleaseSelectAnAreaCode: 'Please select an area code',
		pleaseSelectNationality: 'Please select nationality',
		justNow: 'Just now',
		aecondsAgo: 'seconds ago',
		minuteAgo: 'minute ago',
		hoursAgo: 'hours ago',
		daysAgo: 'days ago',
	},
	EA: {
		EA: 'Expert',
		Indicators: 'Indicators',
		free: 'Free',
		Free: 'FREE',
		download: 'Download',
		more: 'more',
	},
	score: {
		clockInAlready: 'I have clocked in',
		notEnoughScore: 'Insufficient integration',
		myScore: 'My integral',
		getIntegral: 'Get integral',
		useIntegral: 'Use integral',
		signedIn: 'signed in',
		integralThreeMonthsStatistics: 'Last three months points statistics',
		CheckInScore: 'Check-in score',
		other: 'other',
		prizesCostPoints: 'Prizes cost points',
		pointsDraw: 'Points draw',
		spend: 'Spend',
		pointToGetALuckyDraw: 'point to get a lucky draw',
		youHave: 'You have',
		moreLuckyDraws: 'more lucky draws',
		integralDetail: 'Integral detail',
		time: 'time',
		income_expenditure: 'income/expenditure',
		remark: 'remark',
		Sign_in: 'Sign-in',
		trading: 'Trading',
		payment: 'payment',
		rewardPointsOrders: 'Reward points for large orders',
		developCustomers: 'Develop customers',
		developCustomerDeposits: 'Develop customer deposits',
		prizeDraw: 'Prize draw',
		exchangeGoods: 'Exchange goods',
		expired: 'expired',
		donate: 'donate',
		congratulationsYourWin: "Congratulations on your win",
		drawRecord: 'Draw record',
		prize: 'prize'
	},
	notice: {
		title: 'Time',
		time: 'Date',
	},
	openwindow: {
		IBReferralLink: 'IB Referral Link',
		copyReferralLink: 'Click and copy the referral link',
	},
	link: {
		GoExperienceNow: 'Experience now',
		BackHomePage: 'The Back to Home page has opened in a new window',
	},
	bankinfo: {
		collectionInformation: 'Collection Information',
		amount: 'Amount',
		order: 'Order',
		currencyOfPayment: 'Currency of payment',
		bankName: 'Bank Name',
		bankAccount: 'Bank Account',
		bankAddress: 'Bank Address',
		swiftCode: 'Swift Code',
		Payee: 'Payee',
		paymentAmount: 'Payment amount',
		two_dimensionalCode: 'Two-dimensional code',
		uploadvoucher: 'Upload voucher',
		close: 'Close',
	},
	init: {
		improveBasicPersonalInformation: 'Improve basic personal information',
		submitDocumentInformation: 'Submit document information',
	},
	highcharts: {
		chartExportMenu: 'Chart export menu',
		fullScreenView: 'View in full screen',
		exitFullScreen: 'Exit full screen',
		printChart: 'Print chart',
		downloadJPEGImages: 'Download JPEG images',
		downloadPDFFile: 'Download PDF file',
		downloadPNGFile: 'Download PNG file',
		downloadSVGFile: 'Download SVG file',
	},
	router: {
		init: 'Improve Information',
		home: 'Home',
		customer: 'My Profile',
		accountInformation: 'Account Profile',
		bankCard: 'Bank Information',
		certificateInformation: 'ID Card',
		accountSecurity: 'Security',
		accountSecurityBindga: 'Google Authenticator',
		adminmanager: 'My Trade Account',
		leverageModify: 'New Leverage',
		customermana: 'Customer Management',
		customerRelationship: 'Customer Relationship',
		signal: 'Copy Trade',
		signalList: 'Signals',
		signalDetails: 'Signal Details',
		myFollowSignal: 'My Subscriptions',
		userSignal: 'My Signals',
		applyLog: 'Application History',
		accountApplyLog: 'Account Application Records',
		followSignalLog: 'My Subscriptions Records',
		signalApplyLog: 'Signal Application Records',
		reportCenter: 'Report Center',
		closingReport: 'Close Position Report',
		positionReport: 'Open Position Report',
		performanceReport: 'Performance Report',
		commissionStatistics: 'Commission',
		commissionReport: 'Commission Report',
		devidenReport: 'Profit sharing',
		moneyManagement: 'Deposit/Withdrawal',
		onlineDeposit: 'Online Deposit',
		transelectronic: 'Bank Wire',
		withdrawalApply: 'Withdrawal',
		accountTransfer: 'Account Transfer',
		balanceHistory: 'Wallet',
		Experts: 'Experts',
		Indicators: 'Indicators',
		ExplainEA: 'Installation Guide',
		score: 'Integral',
		myScore: 'My integral',
		promotionLink: 'Referral Link',
		layoutLinkView: 'LinkView',
		backToFrontPage: 'Back to the homepage',
		productIndex: 'Products',
		noticeIndex: 'Notice',
		noticeDetails: 'Notice Details',	
		product: 'Product Management',
		myProduct: 'Holding Products',
		productBuy: 'Buying Records',
		productSell: 'Selling Records',		
		details: 'Details'
	},
	staticRoutes: {
		signIn: 'signIn',
		notFound: 'notFound',
		noPower: 'noPower',
	},
	user: {
		title0: 'Component size',
		title1: 'Language switching',
		title2: 'Menu search',
		title3: 'Layout configuration',
		title4: 'news',
		title5: 'Full screen on',
		title6: 'Full screen off',
		dropdownDefault: 'default',
		dropdownMedium: 'medium',
		dropdownSmall: 'small',
		dropdownMini: 'mini',
		home: 'Home',
		modifyPassword: 'Modify Password',
		profilePhoto: 'Profile photo',
		logOut: 'Log Out',
		uploadPhoto: 'Upload photo',
		confirm: 'Confirm',
		searchPlaceholder: 'Menu search: support Chinese, routing path',
		newTitle: 'Notice',
		newBtn: 'All read',
		newGo: 'Go to the notification center',
		newDesc: 'No notice',
		logOutTitle: 'Tips',
		logOutMessage: 'This operation will log out. Do you want to continue?',
		logOutConfirm: 'determine',
		logOutCancel: 'cancel',
		logOutExit: 'Exiting',
		logOutSuccess: 'Exit successfully!',
	},
	tagsView: {
		refresh: 'refresh',
		close: 'close',
		closeOther: 'closeOther',
		closeAll: 'closeAll',
		fullscreen: 'fullscreen',
	},
	notFound: {
		foundTitle: 'Wrong address input, please re-enter the address~',
		foundMsg: 'You can check the web address first, and then re-enter or give us feedback.',
		foundBtn: 'Back to home page',
	},
	noAccess: {
		accessTitle: 'You are not authorized to operate~',
		accessBtn: 'Reauthorization',
	},
	layout: {
		configTitle: 'Layout configuration',
		oneTitle: 'Global Themes',
		twoTitle: 'Menu / top bar',
		twoTopBar: 'Top bar background',
		twoMenuBar: 'Menu background',
		twoColumnsMenuBar: 'Column menu background',
		twoTopBarColor: 'Top bar default font color',
		twoMenuBarColor: 'Menu default font color',
		twoColumnsMenuBarColor: 'Default font color bar menu',
		twoIsTopBarColorGradual: 'Top bar gradient',
		twoIsMenuBarColorGradual: 'Menu gradient',
		twoIsMenuBarColorHighlight: 'Menu font highlight',
		threeTitle: 'Interface settings',
		threeIsCollapse: 'Menu horizontal collapse',
		threeIsUniqueOpened: 'Menu accordion',
		threeIsFixedHeader: 'Fixed header',
		threeIsClassicSplitMenu: 'Classic layout split menu',
		threeIsLockScreen: 'Open the lock screen',
		threeLockScreenTime: 'screen locking(s/s)',
		fourTitle: 'Interface display',
		fourIsShowLogo: 'Sidebar logo',
		fourIsBreadcrumb: 'Open breadcrumb',
		fourIsBreadcrumbIcon: 'Open breadcrumb icon',
		fourIsTagsview: 'Open tagsview',
		fourIsTagsviewIcon: 'Open tagsview Icon',
		fourIsCacheTagsView: 'Enable tagsview cache',
		fourIsSortableTagsView: 'Enable tagsview drag',
		fourIsFooter: 'Open footer',
		fourIsGrayscale: 'Grey model',
		fourIsInvert: 'Color weak mode',
		fourIsDark: 'Dark Mode',
		fourIsWartermark: 'Turn on watermark',
		fourWartermarkText: 'Watermark copy',
		fiveTitle: 'Other settings',
		fiveTagsStyle: 'Tagsview style',
		fiveAnimation: 'page animation',
		fiveColumnsAsideStyle: 'Column style',
		fiveColumnsAsideLayout: 'Column layout',
		sixTitle: 'Layout switch',
		sixDefaults: 'One',
		sixClassic: 'Two',
		sixTransverse: 'Three',
		sixColumns: 'Four',
		tipText: 'Click the button below to copy the layout configuration to `/src/store/modules/themeConfig.js` It has been modified in.',
		copyText: 'replication configuration',
		resetText: 'restore default',
		copyTextSuccess: 'Copy succeeded!',
		copyTextError: 'Copy failed!',
	},
	upgrade: {
		title: 'New version',
		msg: 'The new version is available, please update it now! Dont worry, the update is fast!',
		desc: 'Prompt: Update will restore the default configuration',
		btnOne: 'Cruel refusal',
		btnTwo: 'Update now',
		btnTwoLoading: 'Updating',
	},
};
