// 定义内容
export default {
	adminmanage: {
    myAccount: {
      leverage: "Leverage",
      equity: "Equity",
      balance: "Balance",
      credit: "Credit",
      freeMargin: "Free Margin",
      spentMargin: "Spent margin",
      buyTime: 'Buying Date',
      buy: 'Buy',
      sell: 'Sell',
      myProduct: 'My Product',
      profitRate: 'Profit',
      details: 'Details',
      history: 'History'
    },
    details: {
      profitChart: 'Profit Trend Chart',
      profit: 'Profit',
      equityChart: 'Equity Trend Chart',
    }
  }
};
