// 定义内容
export default {
	home: {
		freeMargin: "利用可能なマージン",
		equity: "正味額",
		commission: "総手数料",
		monthlyCommission: "今月のコミッション",
		deposit: "入金",
		withdrawal: "出金",
		IBQuantity: "IBの数",
		numberOfCustomer: "顧客数",
		volume: "取引量",
		monthlyDeposit_withdrawal: "毎月の入出金統計",
		USD: "米ドル($)",
		Balance: "合計金額",
		totalBalance: "総資産",
		totalwithdrawal: "総引き出し",
		totalDeposit: "合計預金",
		totalEquity: "合計正味額",
		symbols: "取引統計",
	}
};
