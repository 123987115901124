// 定义内容
export default {
	money: {
    currencySelect: "货币选择",
    comment: "备注",
    name: "姓名",
    withdrawalAmount: "出金金额",
    continue: "提交",
    balanceAccount: "余额账户",
    transactionAccount: "交易账户",
    commissionAccount: "佣金账户",
    deposit: {
      paymentMethod: "支付方式",
      BankWire: "Bank Wire",
      depositAmount: "支付金额",
      currentExchangeRate: "当前汇率",
      dollars: "充值金额",
      payment: "立即支付",
    },
    transelectronic: {
      bankName: "银行名称",
      bankAccount: "银行账号",
      SWIFT: "SWIFT代码",
      bankAddress: "银行地址",
      receivables: "收款方",
    },
    withdrawal: {
      availableBalance: "可用余额",
      bankInformation: "银行信息",
    },
    accountTransfer: {
      from: "从",
      to: "到",
      account: "账户",
      amount: "金额",
      fullTransfer: "全部划转",
      maximumRotatable: "最多可转",
      transferAccount_pleasePositions: "从交易账户划转资金,请先将该账户的持仓全部平仓",
    },
    balancehistory: {
      startTime: "开始时间",
      endTime: "结束时间",
      order: "订单号",
      amount: "金额($)",
      MT4Account: "MT4账户",
      date: "日期",
      state: "状态",
      annotation: "注释",
      income: "收入",
      rollOut: "转出",
      shiftTo: "转入",
      withdrawal: "提现",
      recharge: "充值",
      balanceAccount: "余额账户",
      transactionAccount: "交易账户",
      commissionAccount: "佣金账户",
      transfer: "划转",
      account: '账户',
      balance: '余额',
      allAccounts: "所有账户",
      totalAssets: "总资产",
    }
  }
};
