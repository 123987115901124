// 繁体中文
export default {
	netWorkError: '網絡錯誤',
	wrongNameOrPassword: '用戶名或密碼錯誤',
	forbiddenToLogin: '禁止登入',
	success: '成功',
	emailFormatError: '郵箱格式不正確',
	pwdLengthError: '密碼長度為6--18比特',
	pwdStrongError: '密碼必須包含數位和大小寫字母',
	mailAlreadyExists: '帳戶已經注册，請更換郵箱',
	registerSuccess: '郵件發送成功，請前往郵箱驗證!',
	registerFailed: '注册失敗，請聯系客服',
	activeExpired: '帳戶驗證失敗，請重新嘗試',
	activeSuccess: '帳戶驗證成功',
	sendMailSuccess: '郵件發送成功',
	accountDisabled: '帳戶已禁用',
	sendMailFailure: '郵件發送失敗，請聯系客服',
	emailNotExist: '郵箱地址不存在',
	setPasswordSuccess: '密碼重置成功',
	setPasswordFailureTryAgain: '密碼重置失敗，請重新嘗試',
	oldPwdNotMatch: '舊密碼不匹配',
	failedTryAgain: '操作失敗，請重新嘗試',
	errorInput: '輸入的資訊有誤，請檢查',
	signalHasPositions: '信號源有持倉',
	hasSubscription: '信號源有跟單帳戶，無法完成操作!',
	alreadySubmitUnScribe: '已經提交了撤離申請',
	closePositionsFirst: '請關閉所有持倉訂單',
	loginNotExist: '帳號不存在',
	alreadyWasSignal: '已經是信號源',
	alreadyCopyTrade: '正在跟單',
	alreadyFollowTheSignal: '已經在跟當前訊號',
	thisLoginSubmitSignalApply: '已經提交訊號申請',
	alreadySubmitCopyApply: '已經提交跟單申請',
	noBankCard: '請添加收款銀行',
	verifyExpired: "驗證失敗",
	notEnoughMoney: "可用餘額不足",
	subscribersOnlyWithdrawOnSunday: "跟單用戶請在每週周日提交出金申請!",
	maxMinDeposit: "最低充值金額:",
	minWithdrawal: "最低提現金額:",
	dailyWithdrawalsLimit: "提現達到單日最高限額!",
	doNotRepeatUpload: "請勿重複上傳",
	alreadyMaxCount: '帳戶數量已達到上限',
	loginTypeError: "錯誤的帳戶類別",
	loginOutOfRange: "帳戶超出範圍!",
	codeSendSuccess: "驗證碼發送成功!",
	gaVerifyFailed:"Google Authenticator驗證失敗",
	keepOneVerifyAtLeast:"至少保留一個安全驗證",
	notEnoughScore:"積分不足",
	clockInAlready:"已經打卡了",
	demoOpenSuccess:"模擬賬戶開設成功，請注意查收郵件"
};
