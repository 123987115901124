// 定义内容
export default {
	report: {
    startTime: "開始時間",
    endTime: "結束時間",
    order: "訂單",
    account: "賬戶",
    type: "類型",
    lots: "手數",
    symbols: "交易品種",
    stopLoss: "止損",
    takeProfit: "止盈",
    openTime: "開倉時間",
    openPrice: "開倉價格",
    closePrice: "平倉價格",
    closeTime: "平倉時間",
    commissionPrice: "手續費",
    profit: "獲利",
    total: "合計",
    closing: {
      swap: "庫存費",
    },
    position: {
      time: "時間",
      price: "價位",
      currentPrice: "現價",
      swap: "隔夜利息",
    },
    performance: {
      name: "姓名",
      date: "日期",
      comment: "註釋",
      amount: "金额",
    },
    commission: {
      account_order: "賬戶/訂單號",
      MT4account: "交易賬戶",
      type: "交易類型",
      volume: "單量",
      Commission: "返佣"
    },
    deviden: {
      comment: "註釋",
      time: "時間",
      profit: "利潤"
    }
  }
};
