// 定义内容
export default {
	home: {
		freeMargin: "可用保证金",
		equity: "净值",
		commission: "总佣金",
		monthlyCommission: "本月佣金",
		deposit: "入金",
		withdrawal: "出金",
		IBQuantity: "IB数量",
		numberOfCustomer: "客户数量",
		volume: "交易量",
		monthlyDeposit_withdrawal: "月出入金统计",
		USD: "美元($)",
		Balance: "总金额",
		totalBalance: "总资产",
		totalwithdrawal: "总出金",
		totalDeposit: "总入金",
		totalEquity: "总净值",
		symbols: "交易品种统计",
	}
};
