// 定义内容
export default {
	app: {
		clientManagementSystem: 'سیستم مدیریت مشتری',
		pleaseSelect: 'لطفا انتخاب کنید',
		loginExpired: 'ورود منقضی شده است، لطفا دوباره وارد شوید',
		youLoggedOut: 'شما خارج شده اید، لطفا دوباره وارد شوید',
		tips: 'نکات',
		total: 'مجموع',
		noAccount: 'شماره حساب',
		youHaveNoAccountYet: 'شما هنوز حسابی ندارید!',
		securityVerification: 'تایید',
		emailVerificationCode: 'کد تایید ایمیل',
		pleaseemailVerificationCode: 'لطفا کد تایید ایمیل را وارد کنید',
		PleaseVerificationCode: 'لطفا کد تایید را وارد کنید',
		Send: 'دریافت کد',
		Resend: 'دوباره بدست آوردن',
		cancel: 'لغو',
		OK: 'تایید',
		pleaseEnterBankName: 'لطفا نام بانک را وارد کنید',
		pleaseEnterBankCard: 'لطفا شماره کارت بانکی خود را وارد کنید',
		pleaseEnterBranchName: 'لطفا نام شاخه را وارد کنید',
		pleaseEnterBankAddress: 'لطفا ادرس بانک خود را وارد کنید',
		cannotBeEmpty: 'نمیتوان خالی بود',
		pleaseEnterEmail: 'لطفا ادرس ایمیل خود را وارد کنید',
		wrongAccountFormat: 'دسته بندی حساب اشتباه است',
		pleaseEnterLastName: 'لطفا نام خانوادگی خود را وارد کنید',
		pleaseEnterFirstName: 'لطفا نام خود را وارد کنید',
		pleaseEnterName: 'لطفاً نام را وارد کنید',
		pleaseEnterCellphoneNumber: 'لطفا شماره تماس خود را وارد نمایید',
		pleaseEnterTradingAccount: 'لطفا یک حساب تجاری وارد کنید',
		pleaseEnterSignalName: 'لطفا نام سیگنال را وارد نمایید.',
		pleaseEnterSignalCommission: 'لطفا کمیسیون سیگنال را وارد کنید',
		pleaseEnterReferralCommission: 'لطفا کمیسیون ارجاع را وارد کنید',
		pleaseEnterOldPassword: 'لطفا رمز عبور قدیمی را وارد نمایید',
		PleaseEnterPassword: 'لطفا رمز عبور خود را وارد نمایید',
		passwordLength6and16: 'طول رمز عبور بین 6 تا 16 بیت است',
		newPasswordsDoNotMatch: 'رمزهای جدید یکسان نیستند',
		pleaseEnterRechargeAmount: 'لطفا مبلغ بالا به بالا را وارد کنید',
		pleaseEnterWithdrawalAmount: 'لطفا مبلغ برداشت را وارد کنید',
		notSufficientFunds: 'با عرض پوزش، اعتبار شما رو به اتمام است',
		pleaseEnterProvinces: 'لطفا استان را وارد نمایید',
		pleaseEnterCity: 'لطفا شهر را وارد نمایید',
		pleaseEnterAddress: 'لطفا آدرس خود را وارد نمایید',
		pleaseSelectAnAreaCode: 'لطفا یک کد منطقه را انتخاب کنید',
		pleaseSelectNationality: 'لطفا ملیت خود را انتخاب کنید',
		justNow: 'فقط',
		aecondsAgo: 'ثانیه پیش',
		minuteAgo: 'دقیقه پیش',
		hoursAgo: 'ساعت پیش',
		daysAgo: 'روز پیش',
	},
	EA: {
		EA: 'کارشناس',
		Indicators: 'شاخص',
		free: 'رایگان',
		Free: 'رایگان',
		download: 'دانلود',
		more: 'بیشتر',
	},
	score: {
		clockInAlready: 'قبلا چک شده است',
		notEnoughScore: 'نقاط ناکافی',
		myScore: 'نکات من',
		getIntegral: 'کسب امتیاز',
		useIntegral: 'بازی با امتیاز',
		signedIn: 'ثبت نام',
		integralThreeMonthsStatistics: 'امار امتیاز برای سه ماه گذشته',
		CheckInScore: 'اعتبارات چک در',
		other: 'دیگر',
		prizesCostPoints: 'قرعه کشی امتیاز مصرف می کند',
		pointsDraw: 'قرعه کشی امتیاز',
		spend: 'تهی کردن',
		pointToGetALuckyDraw: 'امتیاز خواهد شد یک فرصت برای برنده شدن قرعه کشی جایزه اهدا',
		youHave: 'شما هنوز هم',
		moreLuckyDraws: 'فرصت قرعه کشی شما',
		integralDetail: 'جزئیات امتیاز',
		time: 'زمان',
		income_expenditure: 'درامد / هزینه ها',
		remark: 'سخن گفتن',
		Sign_in: 'ورود به برنامه',
		trading: 'تجارت',
		payment: 'پرداخت',
		rewardPointsOrders: 'امتیاز پاداش برای سفارشات بزرگ',
		developCustomers: 'رشد مشتریان',
		developCustomerDeposits: 'سپرده های مشتری توسعه',
		prizeDraw: 'قرعه',
		exchangeGoods: 'بازخرید موارد',
		expired: 'گذشته',
		donate: 'اهدای',
		congratulationsYourWin: "تبریک برای گرفتن ان",
		drawRecord: 'رکورد قرعه کشی',
		prize: 'جایزه'
	},
	notice: {
		title: 'عنوان',
		time: 'تاریخ',
	},
	openwindow: {
		IBReferralLink: 'لینک باز کردن حساب معرف (IB)',
		copyReferralLink: 'برای کپی کردن لینک معرف کلیک نمایید',
	},
	link: {
		GoExperienceNow: 'سر به تجربه امروز',
		BackHomePage: 'صفحه "بازگشت به خانه" در یک پنجره جدید باز شده است',
	},
	bankinfo: {
		collectionInformation: 'اطلاعات مجموعه',
		amount: 'بالانس',
		order: 'سفارش',
		currencyOfPayment: 'ارز پرداخت',
		bankName: 'بانک',
		bankAccount: 'حساب بانکی',
		bankAddress: 'آدرس بانک',
		swiftCode: 'کد سوئیفت',
		Payee: 'حساب سپرده',
		paymentAmount: 'مبلغ پرداخت',
		two_dimensionalCode: 'کد QR',
		uploadvoucher: 'بارگزاری گواهی',
		close: 'بستن',
	},
	init: {
		improveBasicPersonalInformation: 'اطلاعات شخصی اولیه را کامل کنید',
		submitDocumentInformation: 'ارسال اطلاعات شناسه',
	},
	highcharts: {
		chartExportMenu: 'منوی صادرات نمودار',
		fullScreenView: 'مشاهده تمام صفحه',
		exitFullScreen: 'خروج از حالت تمامصفحه',
		printChart: 'چاپ نمودار',
		downloadJPEGImages: 'دانلود تصاویر JPEG',
		downloadPDFFile: 'دانلود فایل PDF',
		downloadPNGFile: 'دانلود فایل PNG',
		downloadSVGFile: 'دانلود فایل SVG',
	},
	router: {
		init: 'اصلاح اطلاعات',
		home: 'خانه',
		customer: 'مشخصات من',
		accountInformation: 'مشخصات حساب',
		bankCard: 'اطلاعات بانکی',
		certificateInformation: 'کارت شناسایی',
		accountSecurity: 'امنیت',
		accountSecurityBindga: 'احراز هویت گوگل',
		adminmanager: 'حساب های معامله گری',
		leverageModify: 'اهرم جدید',
		customermana: 'مدیریت مشتری',
		customerRelationship: 'پنل درختی معرف',
		signal: 'سیگنال ها',
		signalList: 'سیگنال',
		signalDetails: 'جزئیات سیگنال',
		myFollowSignal: 'الگوبرداری های معاملاتی من',
		userSignal: 'سیگنال های من',
		applyLog: 'تاریخچه برنامه',
		accountApplyLog: 'عملیات حساب',
		followSignalLog: 'عملیات اشتراک',
		signalApplyLog: 'عملیات سیگنال من',
		reportCenter: 'مرکز گزارش',
		closingReport: 'بستن تاریخچه موقعیت',
		positionReport: 'باز نمودن تاریخچه موقعیت',
		performanceReport: 'گزارش عملکرد',
		commissionStatistics: 'کمیسیون',
		commissionReport: 'گزارش کمیسیون',
		devidenReport: 'تقسیم سود',
		moneyManagement: 'واریز/برداشت',
		onlineDeposit: 'سپرده',
		transelectronic: 'واریز بانکی',
		withdrawalApply: 'برداشت',
		accountTransfer: 'انتقال سرمایه ها',
		balanceHistory: 'کیف پول',
		Experts: 'کارشناس',
		Indicators: 'شاخص',
		ExplainEA: 'راهنمای نصب',
		score: 'انتگرال',
		myScore: 'انتگرال من',
		promotionLink: 'لینک معرفی',
		layoutLinkView: 'لینکView',
		backToFrontPage: 'بازگشت به صفحه اول',
		productIndex: 'محصولات',
		noticeIndex: 'اعلام',
		noticeDetails: 'جزئیات اگهی',	
		product: 'مدیریت محصول',
		myProduct: 'برگزاری محصول',
		productBuy: 'تاریخچه خرید',
		productSell: 'خرید رکورد',		
		details: 'جزئیات'
	},
	staticRoutes: {
		signIn: 'ورود',
		notFound: 'این صفحه پیدا نشد',
		noPower: 'بدون مجوز',
	},
	user: {
		title0: 'اندازه کامپوننت',
		title1: 'تعویض زبان',
		title2: 'جستجوی منو',
		title3: 'پیکربندی طرحبندی',
		title4: 'پیام',
		title5: 'روشن کردن تمام صفحه',
		title6: 'خاموش کردن تمام صفحه',
		dropdownDefault: '默认',
		dropdownMedium: '中等',
		dropdownSmall: '小型',
		dropdownMini: '超小',
		home: 'خانه',
		modifyPassword: 'رمز عبور را تغییر دهید',
		profilePhoto: 'عکس پروفایل',
		logOut: 'خروج',
		uploadPhoto: 'بارگزاری عکس',
		confirm: 'تایید',
		searchPlaceholder: '菜单搜索：支持中文、路由路径',
		newTitle: '通知',
		newBtn: '全部已读',
		newGo: '前往通知中心',
		newDesc: '暂无通知',
		logOutTitle: '提示',
		logOutMessage: '此操作将退出登录, 是否继续?',
		logOutConfirm: '确定',
		logOutCancel: '取消',
		logOutExit: '退出中',
		logOutSuccess: '安全退出成功！',
	},
	tagsView: {
		refresh: 'سرخ شد',
		close: 'خاموش شدن',
		closeOther: 'خاموش کردن سایر',
		closeAll: 'بستن همه',
		fullscreen: 'صفحهٔ جاری تمامصفحه است',
	},
	notFound: {
		foundTitle: 'ادرس اشتباه وارد شده است، لطفا ادرس را دوباره وارد کنید ~',
		foundMsg: 'شما می توانید ابتدا URL را بررسی کنید و دوباره ان را وارد کنید یا بازخورد خود را به ما بدهید.',
		foundBtn: 'بازگشت به صفحه اصلی',
	},
	noAccess: {
		accessTitle: 'شما مجاز نیستید و اجازه کار ندارید',
		accessBtn: 'مجوز مجدد',
	},
	layout: {
		configTitle: '布局配置',
		oneTitle: '全局主题',
		twoTitle: '菜单 / 顶栏',
		twoTopBar: '顶栏背景',
		twoMenuBar: '菜单背景',
		twoColumnsMenuBar: '分栏菜单背景',
		twoTopBarColor: '顶栏默认字体颜色',
		twoMenuBarColor: '菜单默认字体颜色',
		twoColumnsMenuBarColor: '分栏菜单默认字体颜色',
		twoIsTopBarColorGradual: '顶栏背景渐变',
		twoIsMenuBarColorGradual: '菜单背景渐变',
		twoIsMenuBarColorHighlight: '菜单字体背景高亮',
		threeTitle: '界面设置',
		threeIsCollapse: '菜单水平折叠',
		threeIsUniqueOpened: '菜单手风琴',
		threeIsFixedHeader: '固定 Header',
		threeIsClassicSplitMenu: '经典布局分割菜单',
		threeIsLockScreen: '开启锁屏',
		threeLockScreenTime: '自动锁屏(s/秒)',
		fourTitle: '界面显示',
		fourIsShowLogo: '侧边栏 Logo',
		fourIsBreadcrumb: '开启 Breadcrumb',
		fourIsBreadcrumbIcon: '开启 Breadcrumb 图标',
		fourIsTagsview: '开启 Tagsview',
		fourIsTagsviewIcon: '开启 Tagsview 图标',
		fourIsCacheTagsView: '开启 TagsView 缓存',
		fourIsSortableTagsView: '开启 TagsView 拖拽',
		fourIsFooter: '开启 Footer',
		fourIsGrayscale: '灰色模式',
		fourIsInvert: '色弱模式',
		fourIsDark: '深色模式',
		fourIsWartermark: '开启水印',
		fourWartermarkText: '水印文案',
		fiveTitle: '其它设置',
		fiveTagsStyle: 'Tagsview 风格',
		fiveAnimation: '主页面切换动画',
		fiveColumnsAsideStyle: '分栏高亮风格',
		fiveColumnsAsideLayout: '分栏布局风格',
		sixTitle: '布局切换',
		sixDefaults: '默认',
		sixClassic: '经典',
		sixTransverse: '横向',
		sixColumns: '分栏',
		tipText: '点击下方按钮，复制布局配置去 `src/store/modules/themeConfig.js` 中修改。',
		copyText: '一键复制配置',
		resetText: '一键恢复默认',
		copyTextSuccess: '复制成功！',
		copyTextError: '复制失败！',
	},
	upgrade: {
		title: '新版本升级',
		msg: '新版本来啦，马上更新尝鲜吧！不用担心，更新很快的哦！',
		desc: '提示：更新会还原默认配置',
		btnOne: '残忍拒绝',
		btnTwo: '马上更新',
		btnTwoLoading: '更新中',
	},
};
