// 定义内容
export default {
	adminmanage: {
    myAccount: {
      leverage: "杠杆",
      equity: "净值",
      balance: "余额",
      credit: "信用",
      freeMargin: "可用保证金",
      spentMargin: "已用保证金",
      buyTime: '购买时间',
      buy: '买入',
      sell: '卖出',
      myProduct: '我的产品',
      profitRate: '利润率',
      details: '详情',
      history: '历史'
    },
    details: {
      profitChart: '利润走势图',
      profit: '利润',
      equityChart: '净值走势图',
    }
  }
};