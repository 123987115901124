// 定义内容
export default {
	adminmanage: {
    myAccount: {
      leverage: "槓桿",
      equity: "淨值",
      balance: "餘額",
      credit: "信用",
      freeMargin: "可用保證金",
      spentMargin: "已用保證金",
      buyTime: '購買時間',
      buy: '買入',
      sell: '賣出',
      myProduct: '我的產品',
      profitRate: '利潤率',
      details: '詳情',
      history: '歷史'
    },
    details: {
      profitChart: '利潤走勢圖',
      profit: '利潤',
      equityChart: '淨值走勢圖',
    }
  }
};