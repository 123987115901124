// 定义内容
export default {
	report: {
    startTime: "开始时间",
    endTime: "结束时间",
    order: "订单",
    account: "账户",
    type: "类型",
    lots: "手数",
    symbols: "交易品种",
    stopLoss: "止损",
    takeProfit: "止盈",
    openTime: "开仓时间",
    openPrice: "开仓价格",
    closePrice: "平仓价格",
    closeTime: "平仓时间",
    commissionPrice: "手续费",
    profit: "获利",
    total: "合计",
    closing: {
      swap: "库存费",
    },
    position: {
      time: "时间",
      price: "价位",
      currentPrice: "现价",
      swap: "隔夜利息",
    },
    performance: {
      name: "姓名",
      date: "日期",
      comment: "注释",
      amount: "金额",
    },
    commission: {
      account_order: "账户/订单号",
      MT4account: "交易账户",
      type: "交易类型",
      volume: "单量",
      Commission: "返佣"
    },
    deviden: {
      comment: "注释",
      time: "时间",
      profit: "利润"
    }
  }
};
