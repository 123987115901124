// 定义内容
export default {
	app: {
		clientManagementSystem: "顧客管理システム",
		pleaseSelect: '選択をお願いします',
		loginExpired: '登録期限が切れていますので,再登録してください',
		youLoggedOut: '登録されていますので再登録してください',
		tips: 'プロンプト',
		total: "合計です",
		noAccount: "アカウントなし",
		youHaveNoAccountYet: "あなたはまだアカウントを持っていません！",
		securityVerification: "安全検証",
		emailVerificationCode: "メール確認コード",
		pleaseemailVerificationCode: "eメール認証コードを入力します。",
		PleaseVerificationCode: "captcha入力をお願いします。",
		Send: "確認コードを取得する",
		Resend: "再送",
		cancel: "キャンセル",
		OK: "確認する",
		pleaseEnterBankName: "銀行名の入力をお願いします",
		pleaseEnterBankCard: "カード番号をお願いします。",
		pleaseEnterBranchName: "支店名を入力願います。",
		pleaseEnterBankAddress: "銀行の住所をお願いします。",
		cannotBeEmpty: "空にはできません",
		pleaseEnterEmail: "メールをお願いします。",
		wrongAccountFormat: "間違ったアカウントカテゴリ",
		pleaseEnterLastName: '姓を入力願います。',
    pleaseEnterFirstName: '名前を入力願います。',
		pleaseEnterName: "名前を入力してください",
		pleaseEnterCellphoneNumber: "電話番号を入力してください。",
		pleaseEnterTradingAccount: "取引口座の入力をお願いします",
		pleaseEnterSignalName: "信号名を入力してください。",
		pleaseEnterSignalCommission: "信号手数料の入力をお願いします。",
		pleaseEnterReferralCommission: "推薦人手数料を入力願います。",
		pleaseEnterOldPassword: "元のパスワードをお願いします。",
		PleaseEnterPassword: "パスワードを入力してください",
		passwordLength6and16: "パスワードの長さは6〜16桁です",
		newPasswordsDoNotMatch: "2回入力したパスワードが矛盾しています。",
		pleaseEnterRechargeAmount: "チャージ金額の入力をお願いします。",
		pleaseEnterWithdrawalAmount: "金額の入力をお願いします。",
		notSufficientFunds: "残高が足りない",
		pleaseEnterProvinces: "省を入力してください",
		pleaseEnterCity: "都市を入力してください",
		pleaseEnterAddress: "住所を入力してください",
		pleaseSelectAnAreaCode: "局番をお願いします。",
		pleaseSelectNationality: "国籍をお願いします",
		justNow: "さっきです",
		aecondsAgo: "秒前です",
		minuteAgo: "分前です",
		hoursAgo: "少し前です",
		daysAgo: "先日です",
	},
	EA: {
		EA: '専門家',
		Indicators: '指標です',
		free: '無料です',
		Free: '無料です',
		download: 'ダウンロードします',
		more: "もっとです"
	},
	score: {
		clockInAlready: 'チェックイン済みです',
		notEnoughScore: 'ポイントが足りません。',
		myScore: '私のポイントです',
		getIntegral: 'ポイントをもらう',
		useIntegral: "積分を回すのです",
		signedIn: "チェックイン済みです",
		integralThreeMonthsStatistics: 'ここ3か月間の集計です',
		CheckInScore: "ポイントにサインする",
		other: 'その他です',
		prizesCostPoints: '抽選でポイントを消費します',
		pointsDraw: 'ポイント抽選です。',
		spend: 'ポイントを',
		pointToGetALuckyDraw: '消費すると抽選になります',
		youHave: '抽選で',
		moreLuckyDraws: '回のチャンスがあります',
		integralDetail: "ポイント明細です",
		time: "時間です",
		income_expenditure: "収入/支出です",
		remark: "備考です",
		Sign_in: "サインインします",
		trading: "取引します",
		payment: "支払います",
		rewardPointsOrders: "大口註文にはポイントがつきます",
		developCustomers: "顧客を増やすことです",
		developCustomerDeposits: "顧客入金を促進します",
		prizeDraw: "くじ引きです",
		exchangeGoods: "品物を両替する",
		expired: "期限切れです",
		donate: "寄付します",
		congratulationsYourWin: "ご獲得おめでとうございます",
		drawRecord: '抽選記録です',
		prize: '賞品です'
	},
	notice: {
		title: '見出しです',
		time: '期日です',
	},
	openwindow: {
		IBReferralLink: 'ＩＢ推進リンク',
		copyReferralLink: "コピー促進リンクをクリック"
	},
	link: {
		GoExperienceNow: "すぐに体験に行きます",
		BackHomePage: 'ページ「トップページに戻ります」が新しい窓で開かれている'
	},
	bankinfo: {
		collectionInformation: "収集情報",
		amount: "金額",
		order: "注文番号",
		currencyOfPayment: "貨幣を支払います。",
		bankName: "銀行名",
		bankAccount: "銀行口座",
		bankAddress: "銀行住所",
		swiftCode: "迅速なコード",
		Payee: "受取人",
		paymentAmount: "お支払い金額",
		two_dimensionalCode: "qrコードです",
		uploadvoucher: "証明書をアップロード",
		close: "閉じる",
	},
	init: {
		improveBasicPersonalInformation: "基本情報を充実させます",
		submitDocumentInformation: "証明書の提出です"
	},
	highcharts: {
		chartExportMenu: 'グラフ導出メニュー',
		fullScreenView: '全画面表示です',
		exitFullScreen: '全画面から退出します',
		printChart: 'グラフを印刷します',
		downloadJPEGImages: 'JPEG画像をダウンロードします',
		downloadPDFFile: 'PDFファイルをダウンロードします',
		downloadPNGFile: 'PNGファイルをダウンロードします',
		downloadSVGFile: 'SVGファイルをダウンロードします'
	},
	router: {
		init: '情報を補完します',
		home: 'ホームページ',
		customer: '個人情報',
		accountInformation: 'アカウント情報',
		bankCard: '銀行情報',
		certificateInformation: '証明書の情報',
		accountSecurity: '安全保障',
		accountSecurityBindga: 'Googleオーセンティケーター',
		adminmanager: '私のアカウント',
		leverageModify: '調整レバー',
		customermana: '顧客管理',
		customerRelationship: '取引先関係',
		signal: '商品化コミュニティ',
		signalList: '信号源',
		signalDetails: '信号の詳細',
		myFollowSignal: '私の注文',
		userSignal: '私の情報源',
		applyLog: '申請記録',
		accountApplyLog: 'アカウント申請記録',
		followSignalLog: 'ドキュメンタリー申請記録',
		signalApplyLog: '信号源アプリケーション記録',
		reportCenter: 'レポートセンター',
		closingReport: '倉卒報告',
		positionReport: '持ち場報告',
		performanceReport: '実績レポート',
		commissionStatistics: 'コミッション統計',
		commissionReport: 'コミッションレポート',
		devidenReport: '利益配当です',
		moneyManagement: '出入金の管理',
		onlineDeposit: 'チャージします',
		transelectronic: '電信送金情報',
		withdrawalApply: 'キャッシュバックです',
		accountTransfer: '口座振替',
		balanceHistory: '財布です',
		Experts: '専門家',
		Indicators: '指標です',
		ExplainEA: 'インストールガイド',
		score: '積分します',
		myScore: '私のポイントです',
		promotionLink: 'リンクの展開',
		layoutLinkView: '外鎖です',		
		backToFrontPage: 'トップページに戻る',
		productIndex: '製品です',
		noticeIndex: '公告します',
		noticeDetails: '詳細を公告します。',	
		product: '製品管理です',
		myProduct: '製品を所有しています',
		productBuy: '購入履歴です',
		productSell: '記録を買います',		
		details: '詳細です'
	},	
	staticRoutes: {
		signIn: 'ログイン',
		notFound: 'このページが見当たりません',
		noPower: '権限がありません',
	},
	user: {
		title0: 'コンポーネントサイズです',
		title1: '言葉の切り替えです',
		title2: '言葉の切り替えです',
		title3: 'レイアウトします。',
		title4: 'メッセージです',
		title5: '全画面開きです',
		title6: '全画面を消します',
		dropdownDefault: '默认',
		dropdownMedium: '中等',
		dropdownSmall: '小型',
		dropdownMini: '超小',
		home: 'ホーム',
		modifyPassword: 'パスワード変更',
		profilePhoto: 'アバターを修正する',
		logOut: 'ログアウト',
		uploadPhoto: "アバターをアップロード",
		confirm: "変更を確認",
		searchPlaceholder: '菜单搜索：支持中文、路由路径',
		newTitle: '通知',
		newBtn: '全部已读',
		newGo: '前往通知中心',
		newDesc: '暂无通知',
		logOutTitle: '提示',
		logOutMessage: '此操作将退出登录, 是否继续?',
		logOutConfirm: '确定',
		logOutCancel: '取消',
		logOutExit: '退出中',
		logOutSuccess: '安全退出成功！',
	},
	tagsView: {
		refresh: 'リフレッシュします',
		close: 'خاموش شدن',
		closeOther: 'その他は閉鎖します',
		closeAll: 'すべて閉鎖します',
		fullscreen: '全画面表示です',
	},
	notFound: {
		foundTitle: '住所を間違って入力しました。住所を再入力してください。',
		foundMsg: 'あなたは先にアドレスをチェックすることができて、それから再度入力または私達に問題をフィードバックします。',
		foundBtn: 'トップページに戻ります',
	},
	noAccess: {
		accessTitle: 'あなたは授権されていないで、操作の権限がありません',
		accessBtn: '再ライセンスです',
	},
	layout: {
		configTitle: '布局配置',
		oneTitle: '全局主题',
		twoTitle: '菜单 / 顶栏',
		twoTopBar: '顶栏背景',
		twoMenuBar: '菜单背景',
		twoColumnsMenuBar: '分栏菜单背景',
		twoTopBarColor: '顶栏默认字体颜色',
		twoMenuBarColor: '菜单默认字体颜色',
		twoColumnsMenuBarColor: '分栏菜单默认字体颜色',
		twoIsTopBarColorGradual: '顶栏背景渐变',
		twoIsMenuBarColorGradual: '菜单背景渐变',
		twoIsMenuBarColorHighlight: '菜单字体背景高亮',
		threeTitle: '界面设置',
		threeIsCollapse: '菜单水平折叠',
		threeIsUniqueOpened: '菜单手风琴',
		threeIsFixedHeader: '固定 Header',
		threeIsClassicSplitMenu: '经典布局分割菜单',
		threeIsLockScreen: '开启锁屏',
		threeLockScreenTime: '自动锁屏(s/秒)',
		fourTitle: '界面显示',
		fourIsShowLogo: '侧边栏 Logo',
		fourIsBreadcrumb: '开启 Breadcrumb',
		fourIsBreadcrumbIcon: '开启 Breadcrumb 图标',
		fourIsTagsview: '开启 Tagsview',
		fourIsTagsviewIcon: '开启 Tagsview 图标',
		fourIsCacheTagsView: '开启 TagsView 缓存',
		fourIsSortableTagsView: '开启 TagsView 拖拽',
		fourIsFooter: '开启 Footer',
		fourIsGrayscale: '灰色模式',
		fourIsInvert: '色弱模式',
		fourIsDark: '深色模式',
		fourIsWartermark: '开启水印',
		fourWartermarkText: '水印文案',
		fiveTitle: '其它设置',
		fiveTagsStyle: 'Tagsview 风格',
		fiveAnimation: '主页面切换动画',
		fiveColumnsAsideStyle: '分栏高亮风格',
		fiveColumnsAsideLayout: '分栏布局风格',
		sixTitle: '布局切换',
		sixDefaults: '默认',
		sixClassic: '经典',
		sixTransverse: '横向',
		sixColumns: '分栏',
		tipText: '点击下方按钮，复制布局配置去 `src/store/modules/themeConfig.js` 中修改。',
		copyText: '一键复制配置',
		resetText: '一键恢复默认',
		copyTextSuccess: '复制成功！',
		copyTextError: '复制失败！',
	},
	upgrade: {
		title: '新版本升级',
		msg: '新版本来啦，马上更新尝鲜吧！不用担心，更新很快的哦！',
		desc: '提示：更新会还原默认配置',
		btnOne: '残忍拒绝',
		btnTwo: '马上更新',
		btnTwoLoading: '更新中',
	},
};
