// 定义内容
export default {
	login: {
		forgetpassword: '忘记密码？',
		noaccount: '没有账户?',
    registernow: '立即注册',
		pleaseinputaccount: '请输入账户',
		pleaseinputpassword: '请输入密码',
		placeholder3: '请输入验证码',
		btnText: '登 录',
		// link: {
		// 	one1: '第三方登录',
		// 	one2: '友情链接',
		// },
		signInText: '欢迎回来！',
	},
	Register: {
		register: '注册',
		hasAccount: '已有账户',
    loginNow: '立即登录',
    userEmail: '邮箱',
    LastName: '姓',
    FirstName: '名',
		userPhone: '电话',
    userPassword: '密码',
    userPasswordAgain: '确认密码',
    userAgent: '推荐人',
    pwdNotMatch: '两次输入的密码不一致!',		
    newDemoAccount: "注册模拟账户",
		Leverage: '杠杆',
		Cellhone: '手机号码',
		form: '客户来源',
		verificationCode: '验证码',
		pleaseSelectLever: '请选择杠杆',
		pleaseEnterCustomerSource: '请输入客户来源'
	},
	ForGetPassword: {
		forgetpassword: "忘记密码",
		retrievePassword: "找回密码",
		pleaseRegisterEmail: "请填写注册时的邮箱",
		Confirm: "确定",
		SetNewPwd: "重置密码"
	}
};
