// 定义内容
export default {
	home: {
		freeMargin: "حاشیه آزاد",
		equity: "موجودی جاری",
		commission: "کمیسیون کل",
		monthlyCommission: "کمیسیون در این ماه",
		deposit: "واریز",
		withdrawal: "برداشتی",
		IBQuantity: "معرف",
		numberOfCustomer: "شماره مشتری",
		volume: "حجم",
		monthlyDeposit_withdrawal: "واریزی های ماهانه / برداشت های ماهانه",
		USD: "دلار",
		Balance: "بالانس",
		totalBalance: "بالانس",
		totalwithdrawal: "مجموع برداشتی ها",
		totalDeposit: "مجموع واریزی ها",
		totalEquity: "موجودی جاری",
		symbols: "نماد",
	}
};
