// 定义内容
export default {
	app: {
		clientManagementSystem: "客户管理系统",
		pleaseSelect: '请选择',
		loginExpired: '登录过期，请重新登录',
		youLoggedOut: '你已被登出，请重新登录',
		tips: '提示',
		total: "合计",
		noAccount: "无账号",
		youHaveNoAccountYet: "您还没有账户!",
		securityVerification: "安全验证",
		emailVerificationCode: "邮件验证码",
		pleaseemailVerificationCode: "请输入邮箱验证码",
		PleaseVerificationCode: "请输入验证码",
		Send: "获取验证码",
		Resend: "重新发送",
		cancel: "取消",
		OK: "确定",
		pleaseEnterBankName: "请输入银行名称",
		pleaseEnterBankCard: "请输入银行卡号",
		pleaseEnterBranchName: "请输入分行名称",
		pleaseEnterBankAddress: "请输入银行地址",
		cannotBeEmpty: "不能为空",
		pleaseEnterEmail: "请输入邮箱",
		wrongAccountFormat: "邮箱格式错误",
		pleaseEnterLastName: '请输入姓',
    pleaseEnterFirstName: '请输入名',
		pleaseEnterName: "请输入姓名",
		pleaseEnterCellphoneNumber: "请输入电话号码",
		pleaseEnterTradingAccount: "请输入交易账户",
		pleaseEnterSignalName: "请输入信号名称",
		pleaseEnterSignalCommission: "请输入信号佣金",
		pleaseEnterReferralCommission: "请输入推荐人佣金",
		pleaseEnterOldPassword: "请输入原密码",
		PleaseEnterPassword: "请输入密码",
		passwordLength6and16: "密码长度为6到16位之间",
		newPasswordsDoNotMatch: "两次输入的密码不一致!",
		pleaseEnterRechargeAmount: "请输入充值金额",
		pleaseEnterWithdrawalAmount: "请输入出金金额",
		notSufficientFunds: "余额不足",
		pleaseEnterProvinces: "请输入省份",
		pleaseEnterCity: "请输入城市",
		pleaseEnterAddress: "请输入地址",
		pleaseSelectAnAreaCode: "请选择区号",
		pleaseSelectNationality: "请选择国籍",
		justNow: "刚刚",
		aecondsAgo: "秒前",
		minuteAgo: "分钟前",
		hoursAgo: "小时前",
		daysAgo: "天前",
	},
	EA: {
    EA: 'EA',
    Indicators: '指标',
		free: '免费',
		Free: '免费',
		download: '下载',
		more: "更多"
  },
	score: {
		clockInAlready: '已签到',
		notEnoughScore: '积分不足',
		myScore: '我的积分',
		getIntegral: '领积分',
		useIntegral: "玩转积分",
		signedIn: "已签到",
		integralThreeMonthsStatistics: '最近三个月积分统计',
		CheckInScore: "签到积分",
		other: '其他',
		prizesCostPoints: '抽奖消耗积分',
		pointsDraw: '积分抽奖',
		spend: '消耗',
    pointToGetALuckyDraw: '积分可获得一次抽奖机会',
    youHave: '您还有',
    moreLuckyDraws: '次抽奖机会',
		integralDetail: "积分明细",
		time: "时间",
		income_expenditure: "收入/支出",
		remark: "备注",
		Sign_in: "签到",
		trading: "交易",
		payment: "支付",
		rewardPointsOrders: "大额订单奖励积分",
		developCustomers: "发展客户",
		developCustomerDeposits: "发展客户入金",
		prizeDraw: "抽奖",
		exchangeGoods: "兑换物品",
		expired: "过期",
		donate: "捐赠",
		congratulationsYourWin: "恭喜你获得了",
		drawRecord: '抽奖记录',
		prize: '奖品'
	},
	notice: {
		title: '标题',
		time: '日期',
	},
	openwindow: {
		IBReferralLink: 'IB推广链接',
		copyReferralLink: "点击复制推广链接"
	},
	link: {
		GoExperienceNow: "立即前往体验",
		BackHomePage: '页面"回到首页"已在新窗口中打开'
	},
	bankinfo: {
		collectionInformation: "收款信息",
		amount: "金额",
		order: "订单号",
		currencyOfPayment: "支付币种",
		bankName: "银行名称",
		bankAccount: "银行账户",
		bankAddress: "银行地址",
		swiftCode: "Swift代码",
		Payee: "收款人",
		paymentAmount: "支付金额",
		two_dimensionalCode: "二维码",
		uploadvoucher: "上传凭证",
		close: "关闭",
	},
	init: {
		improveBasicPersonalInformation: "完善个人基本信息",
		submitDocumentInformation: "提交证件信息"
	},
	highcharts: {
		chartExportMenu: '图表导出菜单',
		fullScreenView: '全屏查看',
		exitFullScreen: '退出全屏',
		printChart: '打印图表',
		downloadJPEGImages: '下载JPEG图片',
		downloadPDFFile: '下载PDF文件',
		downloadPNGFile: '下载PNG文件',
		downloadSVGFile: '下载SVG文件'
	},
	router: {
		init: '完善信息',
		home: '主页',
		customer: '个人信息',
		accountInformation: '账户信息',
		bankCard: '银行信息',
		certificateInformation: '证件信息',
		accountSecurity: '账户安全',
		accountSecurityBindga: '谷歌验证器',
		adminmanager: '我的账户',
		leverageModify: '调整杠杆',
		customermana: '客户管理',
		customerRelationship: '客户关系',
		signal: '跟单社区',
		signalList: '信号',
		signalDetails: '信号详情',
		myFollowSignal: '我的跟单',
		userSignal: '我的信号',
		applyLog: '申请记录',
		accountApplyLog: '账户申请记录',
		followSignalLog: '跟单申请记录',
		signalApplyLog: '信号申请记录',
		reportCenter: '报表中心',
		closingReport: '平仓报告',
		positionReport: '持仓报告',
		performanceReport: '业绩报表',
		commissionStatistics: '佣金统计',
		commissionReport: '佣金报告',
		devidenReport: '利润分红',
		moneyManagement: '资金管理',
		onlineDeposit: '充值',
		transelectronic: '电汇信息',
		withdrawalApply: '提现',
		accountTransfer: '账户转账',
		balanceHistory: '钱包',
		Experts: 'EA',
		Indicators: '指标',
		ExplainEA: '安装指南',
		score: '积分',
		myScore: '我的积分',
		promotionLink: '推广链接',
		layoutLinkView: '外链',
		backToFrontPage: '回到首页',	
		productIndex: '产品',
		noticeIndex: '公告',
		noticeDetails: '公告详情',	
		product: '产品管理',
		myProduct: '持有产品',
		productBuy: '买入记录',
		productSell: '买出记录',		
		details: '详情'
	},
	staticRoutes: {
		signIn: '登录',
		notFound: '找不到此页面',
		noPower: '没有权限',
	},
	user: {
		title0: '组件大小',
		title1: '语言切换',
		title2: '菜单搜索',
		title3: '布局配置',
		title4: '消息',
		title5: '开全屏',
		title6: '关全屏',
		dropdownDefault: '默认',
		dropdownMedium: '中等',
		dropdownSmall: '小型',
		dropdownMini: '超小',
		home: '首页',
		modifyPassword: '修改密码',
		profilePhoto: '修改头像',
		logOut: '退出登录',
		uploadPhoto: "上传头像",
		confirm: "确认修改",
		searchPlaceholder: '菜单搜索：支持中文、路由路径',
		newTitle: '通知',
		newBtn: '全部已读',
		newGo: '前往公告中心',
		newDesc: '暂无通知',
		logOutTitle: '提示',
		logOutMessage: '此操作将退出登录, 是否继续?',
		logOutConfirm: '确定',
		logOutCancel: '取消',
		logOutExit: '退出中',
		logOutSuccess: '安全退出成功！',
	},
	tagsView: {
		refresh: '刷新',
		close: '关闭',
		closeOther: '关闭其它',
		closeAll: '全部关闭',
		fullscreen: '当前页全屏',
	},
	// 404
	notFound: {
		foundTitle: '地址输入错误，请重新输入地址~',
		foundMsg: '您可以先检查网址，然后重新输入或给我们反馈问题。',
		foundBtn: '返回首页',
	},
	// 401
	noAccess: {
		accessTitle: '您未被授权，没有操作权限~',
		accessBtn: '重新授权',
	},
	layout: {
		configTitle: '布局配置',
		oneTitle: '全局主题',
		twoTitle: '菜单 / 顶栏',
		twoTopBar: '顶栏背景',
		twoMenuBar: '菜单背景',
		twoColumnsMenuBar: '分栏菜单背景',
		twoTopBarColor: '顶栏默认字体颜色',
		twoMenuBarColor: '菜单默认字体颜色',
		twoColumnsMenuBarColor: '分栏菜单默认字体颜色',
		twoIsTopBarColorGradual: '顶栏背景渐变',
		twoIsMenuBarColorGradual: '菜单背景渐变',
		twoIsMenuBarColorHighlight: '菜单字体背景高亮',
		threeTitle: '界面设置',
		threeIsCollapse: '菜单水平折叠',
		threeIsUniqueOpened: '菜单手风琴',
		threeIsFixedHeader: '固定 Header',
		threeIsClassicSplitMenu: '经典布局分割菜单',
		threeIsLockScreen: '开启锁屏',
		threeLockScreenTime: '自动锁屏(s/秒)',
		fourTitle: '界面显示',
		fourIsShowLogo: '侧边栏 Logo',
		fourIsBreadcrumb: '开启 Breadcrumb',
		fourIsBreadcrumbIcon: '开启 Breadcrumb 图标',
		fourIsTagsview: '开启 Tagsview',
		fourIsTagsviewIcon: '开启 Tagsview 图标',
		fourIsCacheTagsView: '开启 TagsView 缓存',
		fourIsSortableTagsView: '开启 TagsView 拖拽',
		fourIsFooter: '开启 Footer',
		fourIsGrayscale: '灰色模式',
		fourIsInvert: '色弱模式',
		fourIsDark: '深色模式',
		fourIsWartermark: '开启水印',
		fourWartermarkText: '水印文案',
		fiveTitle: '其它设置',
		fiveTagsStyle: 'Tagsview 风格',
		fiveAnimation: '主页面切换动画',
		fiveColumnsAsideStyle: '分栏高亮风格',
		fiveColumnsAsideLayout: '分栏布局风格',
		sixTitle: '布局切换',
		sixDefaults: '默认',
		sixClassic: '经典',
		sixTransverse: '横向',
		sixColumns: '分栏',
		tipText: '点击下方按钮，复制布局配置去 `src/store/modules/themeConfig.js` 中修改。',
		copyText: '一键复制配置',
		resetText: '一键恢复默认',
		copyTextSuccess: '复制成功！',
		copyTextError: '复制失败！',
	},
	upgrade: {
		title: '新版本升级',
		msg: '新版本来啦，马上更新尝鲜吧！不用担心，更新很快的哦！',
		desc: '提示：更新会还原默认配置',
		btnOne: '残忍拒绝',
		btnTwo: '马上更新',
		btnTwoLoading: '更新中',
	},
};
