// 定义内容
export default {
	money: {
    currencySelect: "貨幣選擇",
    comment: "備註",
    name: "姓名",
    withdrawalAmount: "出金金額",
    continue: "提交",
    balanceAccount: "餘額賬戶",
    transactionAccount: "交易賬戶",
    commissionAccount: "佣金賬戶",
    deposit: {
      paymentMethod: "支付方式",
      BankWire: "Bank Wire",
      depositAmount: "支付金額",
      currentExchangeRate: "當前匯率",
      dollars: "充值金額",
      payment: "立即支付",
    },
    transelectronic: {
      bankName: "銀行名稱",
      bankAccount: "銀行帳號",
      SWIFT: "Swift代碼",
      bankAddress: "銀行地址",
      receivables: "收款方",
    },
    withdrawal: {
      availableBalance: "可用餘額",
      bankInformation: "銀行信息",
    },
    accountTransfer: {
      from: "從",
      to: "到",
      account: "賬戶",
      amount: "金額",
      fullTransfer: "全部劃轉",
      maximumRotatable: "最多可轉",
      transferAccount_pleasePositions: "從交易賬戶劃轉資金,請先將該賬戶的持倉全部平倉",
    },
    balancehistory: {
      startTime: "開始時間",
      endTime: "結束時間",
      order: "訂單號",
      amount: "金額($)",
      MT4Account: "MT4賬戶",
      date: "日期",
      state: "狀態",
      annotation: "註釋",
      income: "收入",
      rollOut: "轉出",
      shiftTo: "轉入",
      withdrawal: "提现",
      recharge: "充值",
      balanceAccount: "餘額賬戶",
      transactionAccount: "交易賬戶",
      commissionAccount: "佣金賬戶",
      transfer: "劃轉",
      account: '賬戶',
      balance: '餘額',
      allAccounts: "所有賬戶",
      totalAssets: "總資產",
    }
  }
};
