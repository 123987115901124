// 定义内容
export default {
  signal: {
    tips: "Tips",
    agree: "Agree",
    refuse: "Refuse",
    OK: "Confirm",
    cancel: "Cancel",
    Submit: "Submit",
    signalName: "Signal Name",
    equity: "Equity",
    balance: "Balance",
    operation: "Operation",
    direction: "Copy Direction",
    positive: "Positive",
    reverse: "Reverse",
    tradeType: "Copy Trade Type",
    percentage: "Percentage",
    fixedLots: "Fixed Lots",
    equityPercentage: "Equity",
    list: {
      all: "All",
      proSignal: "Common signal",
      socialSignal: "Private signal",
      monthlyProfit: "Monthly(%)",
      currentProfit: "Profit Ratio(%)",
      dailyProfit: "Daily Profit(%)",
      maxDrawdown: "Max Drawdown(%)",
      leverage: "Leverage",
      profit: "Profit",
      lots: "Volume",
      weeklyProfit: "Weekly Profit",
      applyForCopy: "Apply for copy",
      tips_text: 'You are carrying out the signal copy operation, if you choose "agree", it means that you have known and agreed to accept the following information content',
      tips_text1: "I have been aware of trading with the signal account, and the setting of relevant parameters belongs to my own independent behavior. I am fully aware of the trading risks of this operation, and I am willing to bear all the results of account profits and losses caused by following the signal account trading, which has nothing to do with others.",
      tips_text2: "I have been aware of the rules and fee standards for copying transactions with the signal account, and agree to deduct the signal subscription fees arising from the relevant copying operations from my copy account.",
      tips_text3: "I have learned that the signal account in the signal source of the merchandising community is provided by the majority of trading enthusiasts, XXXX is not responsible for the trading results and risks of any signal account.",
      applyTrade: "Subscribe",
      applyTradeTips: "Tips: The account number can not repeat subscribe, And copy account can't be a signal",
      tradeLogin: "Subscribers",      
      tradeValue: "Copy Trade Value",
      percentageLeast1: "The percentage of duplicates is at least 1",
      fixedLotsLeast1: "The minimum number of fixed hands is 0.01"      
    },
    myfollow: {
      tradingAccount: "Trading Account",
      signalState: "Signal State",
      Normal: "Normal",
      Unavailable: "Unavailable",
      creationTime: "Creation Time",
      unSubscribe: "Unsubscribe",
      applyForUnsubscribe: "Application for unsubscribe",
    },
    userSignal: {
      createSignal: "Create Signal",
      tips_text1: "Signal accounts with a balance of less than $100, and no additional funds, no transactions, and no documentation within a week will be removed from the documentation community system",
      tips_text2: "Signal accounts suspected of using illegal trading methods for malicious arbitrage will be frozen for 30 natural days. During the freezing of signal accounts, MT4 and CRM systems cannot be logged in; During the period when the signal account is frozen, the profit and loss of the position orders in the account shall be borne by the Signal account holder.",
      tips_text3: "Confirm the use of illegal trading means for malicious arbitrage signal account, its signal account and the same name account will be permanently blocked, signal account holders will be included in the signal blacklist and prohibited from opening transactions in XXXXX. The profit and loss of the position order during the period of the signal account is borne by the signal account holder.",
      MT4Account: "MT4 Account",
      profitShareAccount: "Profit Share Account",
      signalCommissions: "Signal Commissions",
      referralCommission: "Referral Commission",
      tradeMethod: "Trade Method",
      manual: "Manual",
      other: "Other",
      percentage: "Percentage",
      applyDate: "Date",
      comment: "Comment",
      dataUpdateTime: "Data update time",
      disabledSignal: "Disable Signal",
      delete: "Delete",
      activateSignal: "Activate Signal",
      applying: "Applying",
      noIdleAccount: "No idle account"
    },
    details: {
      balanceDetails: "Balance Details",
      fundBalance: "Balance",
      equityDetails: "Equity Details",
      deposit: "Deposit",
      netProfit: "Net Profit",
      profitRatio: "Profit Ratio",
      profitOrders: "Profit Orders",
      lossOrders: "Loss Orders",
      trades: "Trades",
      signalDetail: "Signal Details",
      totalDeposit: "Total Deposit",
      totalWithdrawal: "Total Withdrawal",
      profit: "Profit",
      loss: "Loss",
      profitRatioNumber: "Profit Ratio",
      volume: "Volume",
      orderQuantity: "Order Quantity",
      longPositionOrders: "Long Position Orders",
      shortPositionOrders: "Short Position Orders",

      signalIntroduction: "Signal Introduction",
      dataStatistics: "Data Statistics",
      profitFactor: "Profit Factor",
      symbolsStatistics: "Symbol",

      orderDetails: "Order Details",
      type: "Type",
      lots: "Volume",
      symbols: "Symbol",
      openTime: "Open Time",
      openPrice: "Open Price",
      stopLoss: "S/L",
      takeProfit: "T/P",
      closeTime: "Close Time",
      closePrice: "Close Price",
      commission: "Commission",
      swap: "Swap",
      tableProfit: "Profit",
      tradeProfile: "Trade Profile",
      volume: "Volume",
      tradingDays: "Trading Days",
      day: "Day",
      maxDrawdown: "Max Drawdown",
      weeklyProfit: "Weekly Profit",
      monthlyProfit: "Monthly Profit",
      totalProfit: "Total Profit"
    }
  }
};
