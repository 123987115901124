export default {
  product: {
    annualization: '年化',
    retract: '回撤',
    riskType: '风险类型',
    radicalType: '激进型',
    balancedType: '平衡型',
    conservativeType: '保守型',
    timeLimit: '期限',
    managementFee: '管理费',
    productAmount: '产品金额',
    buy: '购买',
    day: '天',
    details: {
      details: '详情',
      product: '产品名称',
      lever: '杠杆',
      group: '组',
      productDescription: '产品描述',
    }
  }
};