// 定义内容
export default {
	login: {
		forgetpassword: 'رمز عبور خود را فراموش کردید؟',
		noaccount: 'چنین حسابی موجود نیست؟',
    registernow: 'باز نمودن حساب جدید',
		pleaseinputaccount: 'لطفا یک شماره حساب وارد کنید',
		pleaseinputpassword: 'لطفا رمز عبور خود را وارد نمایید',
		placeholder3: 'لطفا کد تایید را وارد کنید',
		btnText: 'ورود',
		// link: {
		// 	one1: '第三方登录',
		// 	one2: '友情链接',
		// },
		signInText: 'خوش آمدید!',
	},
	Register: {
		register: 'ثبت نام',
		hasAccount: 'از قبل حساب کاربری دارید؟',
    loginNow: 'ورود',
    userEmail: 'ایمیل',
    LastName: 'فامیل',
    FirstName: 'نام',
		userPhone: 'شماره تماس',
    userPassword: 'رمز عبور',
    userPasswordAgain: 'تایید رمز عبور',
    userAgent: 'معرف',
    pwdNotMatch: 'رمز عبورها همسان نیستند',
    newDemoAccount: "حساب های آزمایشی",
		Leverage: 'اهرم',
		Cellhone: 'تلفن',
		form: 'منبع مشتری',
		verificationCode: 'کد تایید',
		pleaseSelectLever: 'لطفا اهرم را انتخاب کنید',
		pleaseEnterCustomerSource: 'لطفا منبع مشتری را وارد کنید'
	},
	ForGetPassword: {
		forgetpassword: 'رمز عبور خود را فراموش کردید؟',
		retrievePassword: "بازیابی رمز عبور",
		pleaseRegisterEmail: "لطفا ایمیلی را که برای ثبت نام استفاده شده است را وارد نمایید.",
		Confirm: "تایید",
		SetNewPwd: "تنظیم مجدد رمز عبور"
	}
};
