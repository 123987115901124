// 定义内容
export default {
	login: {
		forgetpassword: '忘記密碼？',
		noaccount: '沒有賬戶?',
    registernow: '立即註冊',
		pleaseinputaccount: '請輸入賬戶',
		pleaseinputpassword: '請輸入密碼',
		placeholder3: '請輸入驗證碼',
		btnText: '登 錄',
		// link: {
		// 	one1: '協力廠商登入',
		// 	one2: '友情連結',
		// },
		signInText: '歡迎回來！',
	},
	Register: {
		register: '註冊',
		hasAccount: '已有賬戶',
    loginNow: '立即登錄',
    userEmail: '郵箱',
    LastName: '姓',
    FirstName: '名',
		userPhone: '電話',
    userPassword: '密碼',
    userPasswordAgain: '確認密碼',
    userAgent: '推薦人',
    pwdNotMatch: '兩次輸入的密碼不一致!',
    newDemoAccount: "注册類比帳戶",
		Leverage: '槓桿',
		Cellhone: '手機號碼',
		form: '客戶來源',
		verificationCode: '驗證碼',
		pleaseSelectLever: '請選擇槓桿',
		pleaseEnterCustomerSource: '請輸入客戶來源'
	},
	ForGetPassword: {
		forgetpassword: "忘記密碼",
		retrievePassword: "找回密碼",
		pleaseRegisterEmail: "請填寫註冊時的郵箱",
		Confirm: "確定",
		SetNewPwd: "重置密碼"
	}
};
