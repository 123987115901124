// 定义内容
export default {
	report: {
    startTime: "Start time",
    endTime: "End time",
    order: "Order",
    account: "Account",
    type: "Type",
    lots: "Volume",
    symbols: "Symbol",
    stopLoss: "S/L",
    takeProfit: "T/P",
    openTime: "Open Time",
    openPrice: "Open Price",
    closePrice: "Close Price",
    closeTime: "Close Time",
    commissionPrice: "Commission",
    profit: "Profit",
    total: "Total",
    closing: {
      swap: "Swap",
    },
    position: {
      time: "Time",
      price: "Price",
      currentPrice: "Price",
      swap: "Swap",
    },
    performance: {
      name: "Name",
      date: "Date",
      comment: "Comment",
      amount: "Amount",
    },
    commission: {
      account_order: "Order",
      MT4account: "MT4 Account",
      type: "Type",
      volume: "Volume",
      Commission: "Commission"
    },
    deviden: {
      comment: "Comment",
      time: "Time",
      profit: "Profit"
    }
  }
};
