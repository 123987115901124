// 定义内容
export default {
	home: {
		freeMargin: "Free Margin",
		equity: "Equity",
		commission: "Total Commission",
		monthlyCommission: "Monthly Commission",
		deposit: "Deposit",
		withdrawal: "Withdrawal",
		IBQuantity: "IB",
		numberOfCustomer: "Number of Customer",
		volume: "Volume",
		monthlyDeposit_withdrawal: "Monthly Deposit/Withdrawal",
		USD: "USD($)",
		Balance: "Balance",
		totalBalance: "Total Balance",
		totalwithdrawal: "Total Withdrawal",
		totalDeposit: "Total Deposit",
		totalEquity: "Equity",
		symbols: "Symbol",
	}
};
