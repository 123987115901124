// 定义内容
export default {
  signal: {
    tips: "プロンプト",
    agree: "同意する",
    refuse: "拒否する",
    OK: "決定する",
    cancel: "キャンセル",
    Submit: "申請書を提出する",
    signalName: "信号名",
    equity: "正味額",
    balance: "バランス",
    operation: "操作",
    direction: "一方向",
    positive: "通常の注文",
    reverse: "逆順",
    tradeType: "ドキュメンタリータイプ",
    percentage: "百分率",
    fixedLots: "固定ロット",
    equityPercentage: "自己資本比率",
    list: {
      all: "全部です",
      proSignal: "共通信号です",
      socialSignal: "プライベートドメイン信号です",
      monthlyProfit: "毎月(%)",
      currentProfit: "経常利益(%)",
      dailyProfit: "毎日の収益性(%)",
      maxDrawdown: "最大引き出し額(%)",
      leverage: "レバレッジ",
      profit: "利益",
      lots: "ロット",
      weeklyProfit: "週間近くの利益",
      applyForCopy: "フォローする申請書",
      tips_text: "「同意する」を選択した場合、以下の情報を理解し、受け入れることに同意したことを意味します。",
      tips_text1: "私は信号アカウントに従って取引を行ったことを知っていて、その関連パラメータの設定は箇人の自主的な行為に属して、完全にこの操作の存在の取引リスクを知っていて、そして自発的に信号アカウントの取引に伴う口座損益のすべての結果を引き受けて、他人とは関系ありません。",
      tips_text2: "私は信号アカウントに従った取引のルールと料金標準を知っていて、関連する取引操作によって発生した信号の購読に関連する費用を私の取引アカウントから差し引くことに同意します。",
      tips_text3: "私は既に取引コミュニティの信号源の中の信号のアカウントが広大な取引の愛好家によって提供されることを知っていて、XXXXはいかなる信号のアカウントの取引の結果とリスクに責任を負いません。",
      applyTrade: "申請書",
      applyTradeTips: "プロンプト: 注文アカウントを注文で繰り返すことはできません, そして、単一のアカウントがソースになることはできません",
      tradeLogin: "ドキュメンタリーアカウント",      
      tradeValue: "ドキュメンタリー型の値",      
      percentageLeast1: "対片手数パーセンテージは最低1です。",
      fixedLotsLeast1: "固定手数は最低0.01です"      
    },
    myfollow: {
      tradingAccount: "取引アカウント",
      signalState: "ソースステータス",
      Normal: "普通",
      Unavailable: "利用できません",
      creationTime: "作成時間",
      unSubscribe: "注文をキャンセルする",
      applyForUnsubscribe: "提出された避難指示書",
    },
    userSignal: {
      createSignal: "信号源になるために適用しなさい",
      tips_text1: "シグナルアカウントの残高が100ドル以下で、一週間以内に追加金、取引、取引なしのシグナルアカウントは、シグナルコミュニティシステムによって削除されます。",
      tips_text2: "不正取引の疑いがあるシグナルアカウントは30日間凍結されます。シグナルアカウントの凍結期間中は、MT4やCRMシステムへのログインができません。信号口座の凍結期間、口座のポジションを持っている註文損益は信号口座の保持者自身が負担します。",
      tips_text3: "不正な取引手段を使って悪意のある裁定取引を行っているシグナルアカウントが確認された場合、シグナルアカウントと同名のアカウントは永久に閉鎖され、シグナルアカウントの所有者はシグナルブラックリストに登録され、XXXXXでの取引が禁止されます。その信号口座閉鎖期間のポジションを持っている註文損益は信号口座の所有者が自分で負担します。",
      MT4Account: "MT4アカウント",
      profitShareAccount: "配当金勘定",
      signalCommissions: "シグナルコミッション",
      referralCommission: "紹介者委員会",
      tradeMethod: "取引手段",
      manual: "マニュアル",
      other: "その他の",
      percentage: "パーセンテージ",
      applyDate: "申し込み時間",
      comment: "備考",
      dataUpdateTime: "データ更新時間",
      disabledSignal: "無効に設定",
      delete: "削除する",
      activateSignal: "アクティベーションを申請する",
      applying: "アプリケーション",
      noIdleAccount: "空いているアカウントがありません。"
    },
    details: {
      balanceDetails: "資金チャート",
      fundBalance: "基金",
      equityDetails: "純資産チャート",
      deposit: "入金",
      netProfit: "純利益",
      profitRatio: "収益性",
      profitOrders: "利益オーダー",
      lossOrders: "損失注文",
      trades: "注文数",
      signalDetail: "信号の詳細",
      totalDeposit: "合計預金",
      totalWithdrawal: "総引き出し",
      profit: "利益",
      loss: "喪失",
      profitRatioNumber: "利益率",
      volume: "取引量",
      orderQuantity: "注文数量",
      longPositionOrders: "複数の注文",
      shortPositionOrders: "空の注文数",

      signalIntroduction: "シグナル入門",
      dataStatistics: "データ統計",
      profitFactor: "勝率分析",
      symbolsStatistics: "取引統計",

      orderDetails: "注文の詳細",
      type: "タイプ",
      lots: "手の数",
      symbols: "取引の多様性",
      openTime: "営業時間",
      openPrice: "始値",
      stopLoss: "ストップロス",
      takeProfit: "利益を取る",
      closeTime: "閉店時間",
      closePrice: "終値",
      commission: "手数料",
      swap: "在庫料",
      tableProfit: "利益",
      tradeProfile: "取引の概要",
      volume: "総取引量",
      tradingDays: "取引日",
      day: "日",
      maxDrawdown: "最大引き出し",
      weeklyProfit: "週間近くの利益",
      monthlyProfit: "ほぼ1ヶ月の収益",
      totalProfit: "総収入"
    }
  }
};
