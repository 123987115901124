// 定义内容
export default {
	apply: {
    Progress: "Progress",
    comment: "Comment",
    applyDate: "Date",
    applicationSuccess: "Application Success",
    applying: "Applying",
    applicationFailed: "Application Failed",
    productName: 'Nmae',
    registrationStatus: "Status",
    registrationDate: "Time",
  }
};