// 定义内容
export default {
	customer: {
		edit: "ویرایش",
		continue: "ادامه",
		cancel: "لغو",
		OK: "مطمئنی",
		account: {
			name: "نام",
			cellPhone: "شماره تماس",
			country: "کشور",
			province: "استان",
			city: "شهر",
			address: "آدرس",
			registrationDate: "زمان ثبت نام",
			account: "حساب",
			noAccount: "بدون حساب",
			clickToCreate: "برای ایجاد کلیک کنید"
		},
		bankcard: {
			payee: "گیرنده",
			SWIFTCode: "کد سوئیفت",
			branchOfBankName: "نام خط شعبه",
			bankAddress: "آدرس بانک",
			newBankProfile: "مشخصات جدید بانکی",
			addBankInformation: "اضافه نمودن اطلاعات حساب بانکی",
			editBankInformation: "ویرایش اطلاعات کارت",
			bankName: "بانک",
			bankAccount: "حساب بانکی",
			branchName: "شعبه",
		},
		certificate: {
			IDCardFrontPendingApproval: "کارت شناسایی در انتظار بررسی است",
			IDCardBackPendingApproval: "کارت شناسایی در انتظار بررسی است",
			BankCardPendingApproval: "بانک در حال بررسی است",
			IDCardFrontApprovalFailed: "بررسی جلوی کارت شناسایی ناموفق بود, لطفا دوباره بارگزاری نمایید",
			IDCardBackApprovalFailed: "بررسی پشت کارت شناسایی ناموفق بود, لطفا دوباره بارگزاری نمایید",
			BankCardApprovalFailed: "کارت بانکی در انتظار بررسی است , لطفا دوباره بارگزاری نمایید",
			IDCardFront: "پاسپورت A",
			IDCardBack: "پاسپورت B",
			BankCard: "اثبات آدرس",
			pictureFormatIncorrect: 'تصویر به درستی قالببندی نشده است',
      uploadMax3M: 'اندازه تصویر اواتار اپلود شده نمی تواند بیش از 3 مگابایت باشد!'
		},
		security: {
			security: "امنیت",
			email: "ایمیل",
			googleAuthenticator: "احراز هویت گوگل",
			transferSecuritySettings: "برای برداشت، انتقال، تغییر تنظیمات امنیتی.",
			loginPassword: "رمز ورود",
			protectAccountSecurity: "برای اطمینان از امنیت حساب",
			bind: "پیوند",
			modify: "تغییر",
			resetCRMLoginPassword: "رمز عبور ورودی CRM را تنظیم مجدد نمایید",
			oldPassword: "رمز عبور قدیمی",
			password: "رمز عبور",
			confirmPassword: "تایید رمز عبور",
			alter: 'تغییر'
		},
		google: {
			GATips: "برنامه GA (Google Authenticator) رمزهای عبور پویا تولید می کند و شبیه به تأیید رمز پویا با SMS است. Google Authenticator هر 30 ثانیه یک کد جدید را پس از اتصال به برنامه ها ایجاد می کند. این کد تأیید را می توان برای برداشت، انتقال و تغییر تنظیمات امنیتی برای دستیابی با حداکثر امنیت استفاده کرد.",
			DownloadGA: "دانلود Google Authenticator",
			DownloadGATips: "کاربران iOS می توانند وارد اپ استور شده و «Authenticator» را برای دانلود جستجو کنند. کاربران اندروید می توانند برای دانلود به Google Play وارد شوند یا «Google Authenticator» را در مرورگر تلفن همراه خود جستجو کنند.",
			AddAndBackUpKeyInYourGA: "کلید را در GA خود اضافه و پشتیبان بگیرید",
      OpenGAAddToken: "برنامه GA را باز کنید و کد QR زیر را اسکن کنید یا کلید را وارد کنید تا یک رمز اضافه نمایید.",
			CopyKey: "کلید الگوبرداری",
			SecretKeySaveTips: "این کلید برای بازیابی GA شما هنگام تعویض یا گم کردن تلفن استفاده می شود. لطفاً قبل از اتصال GA، کلید را ذخیره و پشتیبان بگیرید.",
			EnterThe6_digit: "کد تأیید 6 رقمی را در GA خود وارد نمایید",
			GACode: "کد Google Authenticator",
			oldGoogleVerification: 'اصلی گوگل تایید شده است',
			newGoogleVerification: 'تایید جدید گوگل'
		}
	}
};
