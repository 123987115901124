// 英语
export default {
	netWorkError: 'Network Error',
	wrongNameOrPassword: 'Username or password error',
	forbiddenToLogin: 'Disable login',
	success: 'Success',
	emailFormatError: 'Email format error',
	pwdLengthError: 'Password length need to be 6-18 characters',
	pwdStrongError: 'Password must be included the capital letter, characters and digits.',
	mailAlreadyExists: 'This email has been registered, please change the new email address.',
	registerSuccess: 'The email has been send successfully, please go to your email to verify!',
	registerFailed: 'Registration failure, please contact the customer service.',
	activeExpired: 'The account verificatioin failure, please try again',
	activeSuccess: 'The account verification successful',
	sendMailSuccess: 'The email has been send successfully.',
	accountDisabled: 'Account has been disabled.',
	sendMailFailure: 'The email has been send failed, please contact the customer service.',
	emailNotExist: 'Email address does not exist.',
	setPasswordSuccess: 'The password reset successfully',
	setPasswordFailureTryAgain: 'The passwored reset failed, please try again.',
	oldPwdNotMatch: 'The original password does not match.',
	failedTryAgain: 'Operation failed, please try again',
	errorInput: 'The input information is incorrect, please check again.',
	signalHasPositions: 'The signal has opening position.',
	hasSubscription: 'The signal has follower accounts, cannot complete operation!',
	alreadySubmitUnScribe: 'Submit the unbinding applicaton already.',
	closePositionsFirst: 'Please close all the opening positions.',
	loginNotExist: 'Account number does not exist.',
	alreadyWasSignal: 'This account is already applying the signal.',
	alreadyCopyTrade: 'Copy trading now.',
	alreadyFollowTheSignal: 'Copy the signal already',
	thisLoginSubmitSignalApply: 'Signal application has been submitted.',
	alreadySubmitCopyApply: 'Copy trading application has been submitted.',
	noBankCard: 'Please add the reciving bank.',
	verifyExpired: "Verification failed",
	notEnoughMoney: "Insufficient available balance",
	subscribersOnlyWithdrawOnSunday: "The follower account need to submit the withdrawal application on Sunday!",
	maxMinDeposit: "Minmum deposit amount:",
	minWithdrawal: "Minmum withdrawal amount:",
	dailyWithdrawalsLimit: "Cash withdrawal reaches the maximum daily limit!",
	doNotRepeatUpload: "Please do not resubmit.",
	alreadyMaxCount: 'The number of account has reached the max limit.',
	loginTypeError: "Wrong account category",
	loginOutOfRange: "Account number exceeds the range of segment!",
	codeSendSuccess: "Sent verify code successsfully!",
	gaVerifyFailed:"Google Authenticator verification failed",
	keepOneVerifyAtLeast:"Keep at least one security verification",
	notEnoughScore:"Not enough socre",
	clockInAlready:"Has clocked in",
	demoOpenSuccess:"Demo account has been successfully opened, please check your email"
};
