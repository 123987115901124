// 定义内容
export default {
	money: {
    currencySelect: "انتخاب ارز",
    comment: "نظر",
    name: "نام",
    withdrawalAmount: "مبلغ برداشت",
    continue: "ادامه",
    balanceAccount: "حساب موجودی",
    transactionAccount: "حساب معاملاتی",
    commissionAccount: "حساب کمیسیون",
    deposit: {
      paymentMethod: "روش پرداخت",
      BankWire: "Bank Wire",
      depositAmount: "مبلغ پرداختی",
      currentExchangeRate: "نرخ ارز فعلی",
      dollars: "مبلغ واریز",
      payment: "پرداخت",
    },
    transelectronic: {
      bankName: "بانک",
      bankAccount: "حساب بانکی",
      SWIFT: "کد سوئیفت",
      bankAddress: "آدرس بانک",
      receivables: "حساب سپرده",
    },
    withdrawal: {
      availableBalance: "بالانس آزاد",
      bankInformation: "اطلاعات بانکی",
    },
    accountTransfer: {
      from: "از",
      to: "وارد",
      account: "حساب",
      amount: "مقدار",
      fullTransfer: "انتقال همه",
      maximumRotatable: "بالا به نوبه خود",
      transferAccount_pleasePositions: "برای انتقال وجه از یک حساب تجاری، لطفا ابتدا تمام موقعیت های موجود در حساب را ببندید",
    },
    balancehistory: {
      startTime: "زمان شروع",
      endTime: "زمان پایان",
      order: "سفارش",
      amount: "مبلغ$",
      MT4Account: "حساب متا تریدر 4",
      date: "زمان",
      state: "حالت",
      annotation: "نظر",
      income: "درآمد",
      rollOut: "رول کردن",
      shiftTo: "به",
      withdrawal: "برداشت",
      recharge: "شارژ",
      balanceAccount: "حساب های موجودی",
      transactionAccount: "حساب های معاملاتی",
      commissionAccount: "حساب های کمیسیون",
      transfer: "انتقال",
      account: 'حساب',
      balance: 'تعادل',
      allAccounts: "همه حسابها",
      totalAssets: "مجموع دارایی ها",
    }
  }
};
