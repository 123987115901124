export default {
  product: {
    annualization: 'سالانه',
    retract: 'در حال کاهش',
    riskType: 'نوع ریسک',
    radicalType: 'تهاجمی',
    balancedType: 'متعادل',
    conservativeType: 'محافظه کار',
    timeLimit: 'مدت',
    managementFee: 'هزینه مدیریت',
    productAmount: 'مقدار محصول',
    buy: 'خرید',
    day: 'آسمان',
    details: {
      details: 'جزئیات',
      product: 'نام محصول',
      lever: 'اهرم',
      group: 'گروه',
      productDescription: 'توضیحات محصول:',
    }
  }
};