// 定义内容
export default {
	login: {
		forgetpassword: 'パスワードを忘れますか？',
		noaccount: 'アカウントがありませんか',
    registernow: '即時登録',
		pleaseinputaccount: '口座の入力をお願いします',
		pleaseinputpassword: 'パスワードを入力してください',
		placeholder3: 'captcha入力をお願いします。',
		btnText: 'ログイン',
		// link: {
		// 	one1: '第三方登录',
		// 	one2: '友情链接',
		// },
		signInText: 'お帰りなさいませ!',
	},
	Register: {
		register: '登録',
		hasAccount: '既存のアカウント',
    loginNow: '今すぐサインイン',
    userEmail: 'Eメール',
    LastName: '姓です',
    FirstName: '名です',
		userPhone: '電話番号',
    userPassword: 'パスワード',
    userPasswordAgain: 'パスワード確認',
    userAgent: '紹介者',
    pwdNotMatch: 'パスワードが2回入力されています',
		"newDemoAccount": "デモアカウントを登録する",
		Leverage: 'レバレッジ',
		Cellhone: '携帯番号',
		form: '顧客ソースです',
		verificationCode: '検証コード',
		pleaseSelectLever: 'テコを選びます',
		pleaseEnterCustomerSource: '顧客情報を入力します。'
	},
	ForGetPassword: {
		forgetpassword: "パスワードを忘れますか",
		retrievePassword: "パスワードを取得する",
		pleaseRegisterEmail: "取得したいメールアドレスを入力してください。",
		Confirm: "決定する",
		SetNewPwd: "パスワードをリセット"
	}
};
