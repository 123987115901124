// 定义内容
export default {
	customer: {
		edit: "编辑",
		continue: "提交",
		cancel: "取消",
		OK: "确定",
		account: {
			name: "姓名",
			cellPhone: "电话",
			country: "国籍",
			province: "省份",
			city: "城市",
			address: "地址",
			registrationDate: "注册时间",
			account: "账户",
			noAccount: "无账户",
			clickToCreate: "去创建"
		},
		bankcard: {
			payee: "收款人",
			SWIFTCode: "SWIFT代码",
			branchOfBankName: "分支行名称",
			bankAddress: "银行地址",
			newBankProfile: "新增银行",
			addBankInformation: "新增银行卡信息",
			editBankInformation: "编辑银行卡信息",
			bankName: "银行名称",
			bankAccount: "银行卡号",
			branchName: "分行名称",
		},
		certificate: {
			IDCardFrontPendingApproval: "身份证正面待审核",
			IDCardBackPendingApproval: "身份证反面待审核",
			BankCardPendingApproval: "银行卡待审核",
			IDCardFrontApprovalFailed: "身份证正面审核失败, 请重新上传",
			IDCardBackApprovalFailed: "身份证反面审核失败, 请重新上传",
			BankCardApprovalFailed: "银行卡审核失败, 请重新上传",
			IDCardFront: "身份证正面			",
			IDCardBack: "身份证反面",
			BankCard: "银行卡证明正面",
			pictureFormatIncorrect: '图片格式不正确',
      uploadMax3M: '上传头像图片大小不能超过 3MB!'
		},
		security: {
			security: "账户安全",
			email: "邮箱",
			googleAuthenticator: "谷歌验证器",
			transferSecuritySettings: "用于出金 转账、修改安全设置时进行安全验证",
			loginPassword: "登录密码",
			protectAccountSecurity: "用于保护账户安全",
			bind: "绑定",
			modify: "修改",
			resetCRMLoginPassword: "重置CRM登录密码",
			oldPassword: "原密码",
			password: "密码",
			confirmPassword: "确认密码",
			alter: '更改'
		},
		google: {
			GATips: "谷歌验证器是一款动态口令工具，工作原理类似短信动态验证。绑定后每30s生成一个动态验证码，验证码可用于提现、转账、修改安全设置等操作的安全验证。",
			DownloadGA: "下载谷歌验证器APP",
			DownloadGATips: "iOS用户登录App Store搜索“Authenticator”下载。安卓用户登录应用商店或使用手机浏览器搜索“谷歌验证器”下载。",
			AddAndBackUpKeyInYourGA: "在谷歌验证器中添加密钥并备份",
      OpenGAAddToken: "打开谷歌验证器，扫描下方二维码或手动输入下述密钥添加验证令牌。",
			CopyKey: "复制",
			SecretKeySaveTips: "密钥用于手机更换或遗失找回谷歌验证器，绑定前请务必将下述密钥备份保存。",
			EnterThe6_digit: "输入谷歌验证器中6位验证码",
			GACode: "谷歌验证码",
			oldGoogleVerification: '原来的谷歌验证',
			newGoogleVerification: '新的谷歌验证'
		}
	}
};
