// 定义内容
export default {
	apply: {
    Progress: "پیشروی",
    comment: "نظر",
    applyDate: "تاریخ",
    applicationSuccess: "برنامه موفق بود",
    applying: "در حال اجرای درخواست",
    applicationFailed: "برنامه ناموفق بود",
    productName: 'نام محصول',
    registrationStatus: "دولت",
    registrationDate: "زمان",
  }
};