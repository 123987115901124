export default {
  product: {
    annualization: '年化',
    retract: '回撤',
    riskType: '風險類型',
    radicalType: '激進型',
    balancedType: '平衡型',
    conservativeType: '保守型',
    timeLimit: '期限',
    managementFee: '管理費',
    productAmount: '產品金額',
    buy: '購買',
    day: '天',
    details: {
      details: '詳情',
      product: '產品名稱',
      lever: '槓桿',
      group: '組',
      productDescription: '產品描述',
    }
  }
};