// 定义内容
export default {
	adminmanage: {
    myAccount: {
      leverage: "اهرم",
      equity: "موجودی جاری",
      balance: "بالانس",
      credit: "اعتبار",
      freeMargin: "حاشیه آزاد",
      spentMargin: "حاشیه استفاده شده",
      buyTime: 'زمان خرید',
      buy: 'فروش',
      sell: 'فروش',
      myProduct: 'محصولات من',
      profitRate: 'حاشیه سود',
      details: 'جزئیات',
      history: 'تاریخ'
    },
    details: {
      profitChart: 'نمودار سود',
      profit: 'سود',
      equityChart: 'Equity چارت سازمانی',
    }
  }
};