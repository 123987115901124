// 定义内容
export default {
	home: {
		freeMargin: "可用保證金",
		equity: "淨值",
		commission: "總佣金",
		monthlyCommission: "本月傭金",
		deposit: "入金",
		withdrawal: "出金",
		IBQuantity: "IB數量",
		numberOfCustomer: "客戶數量",
		volume: "交易量",
		monthlyDeposit_withdrawal: "月出入金統計",
		USD: "美元($)",
		Balance: "總金額",
		totalBalance: "總資產",
		totalwithdrawal: "總出金",
		totalDeposit: "總入金",
		totalEquity: "總淨值",
		symbols: "交易品種統計",
	}
};
