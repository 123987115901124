import store from '@/store';
import { $t } from '@/i18n/index.js';
import router, { resetRouter } from '@/router/index';
import axios from 'axios';
import QS from 'qs'
import { Message, MessageBox } from 'element-ui';
import { Session, Local } from '@/utils/storage';
import Config from '@/utils/configs.js';

// 创建 axios 实例
const service = axios.create({
	// baseURL: process.env.VUE_APP_BASE_API,
	baseURL: Config.apiUrl,
	timeout: Config.timeOut,
	headers: { 'Content-Type': 'application/json;charset=UTF-8' },
});

// 添加请求拦截器
service.interceptors.request.use(
	(config) => {
		// 在发送请求之前做些什么 token
		let lang = Local.get('themeConfigPrev').globalI18n;
		config.headers["Lang"] = lang;
		if (Session.get('token')) {
			config.headers.common['Authorization'] = "Bearer " + Session.get('token');
		}
		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);
var messageBoxFlag = 0; 
// 添加响应拦截器
service.interceptors.response.use(
	(response) => {
		// 对响应数据做点什么
		const res = response.data;
		
		// console.log('response', res);
		// console.log('responseresponse', response);
		if (res.code && res.code !== 0) {
			// `token` 过期或者账号已在别处登录
			if (res.code === 401 || res.code === 4001) {
				// 清除浏览器全部临时缓存
				Session.clear(true);
				router.push('/login');
				resetRouter(); // 重置路由
				if (messageBoxFlag === 0) {
					messageBoxFlag = 1 //修改标记，打开 MessageBox
					MessageBox.alert($t('message.app.youLoggedOut'), $t('message.app.tips'), {})
					.then(() => { messageBoxFlag = 0 })
					.catch(() => { messageBoxFlag = 0 });
				}				
			}
			// return Promise.reject(service.interceptors.response.error);
			return response.data;
		} else {
			return response.data;
		}
	},
	(error) => {
		console.log('cow错误', error, error.response, error.message);
		const res = error.response;
		if (res.status === 401) {
			Session.clear(true);
			router.push('/login');
			if (messageBoxFlag === 0) {
				messageBoxFlag = 1 //修改标记，打开 MessageBox
				MessageBox.alert($t('message.app.youLoggedOut'), $t('message.app.tips'), {})
				.then(() => { messageBoxFlag = 0 })
				.catch(() => { messageBoxFlag = 0 });
			}
		}else if (error.response.status === 500) {
			Message.error("Server Error");
		}else if (error.message === 'Network Error') {
			Message.error('Network Error');
		}
		return Promise.reject(error);
	}
);
// 导出 axios 实例
class request {
	// static imageurl = Config.imageUrl;
	// static imageBase64Url = Config.imageBase64Url;

	static async request(url, method, params) {
		return await service.request({
			url: url,
			method: method,
			params: params,
		});
	}

	static async get(url, params) {
		return await service.get(url, { params: params });
	}

	static async post(url, params) {
		//var postss = QS.stringify(params);
		//console.log(params);
		return await service.post(url, params);
	}

	static async put(url, params) {
		return await service.put(url, params);
	}

	static async delete(url, params) {
		//return await service.delete(url, { params: QS.stringify(params) });
		return await service.delete(url, { params: params });
	}
	/**
	 * 上传如片
	 * @param {any} url
	 * @param {any} params {"file":"file对象","directory":"上传文件夹"}
	 *  base64  {base64Str:base64}
	 */
	static async upload(url, params) {
		const formData = new FormData();
		Object.keys(params).forEach((key) => {
			formData.append(key, params[key]);
		});
		let config = {
			headers: { 'Content-Type': 'multipart/form-data' },
		};
		return await service.post(url, formData, config);
	}
}
export default request;
